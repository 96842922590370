import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import featuresImage1 from '../../../svg/features/1.png';
import featuresImage11 from '../../../svg/features/1-1.png';
import breadcrumpImage from '../../../svg/features/1.svg';
import featuresImage2 from '../../../svg/features/2.png';
import featuresImage22 from '../../../svg/features/2-2.png';
import featuresImage3 from '../../../svg/features/3.png';
import featuresImage33 from '../../../svg/features/3-3.png';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '../../../common/Container';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {BsShieldCheck} from 'react-icons/bs';
import {AiOutlineLike} from 'react-icons/ai';
import {PiUsersFourLight} from 'react-icons/pi';
import {Parallax} from 'react-scroll-parallax';

const Features = () => {
    const {t, i18n} = useTranslation();
    const pageSize = useSelector((state) => state.main.pageSize);
    useEffect(() => {
        if (location.hash) {
            setTimeout(() => {
                const top = document.getElementsByClassName(location.hash.replace('#', ''))[0].offsetTop;
                window.scrollTo({top, behavior: 'smooth'});
            }, 100);
        } else {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }, []);

    return (
        <>
            <Box sx={{
                position: 'relative'
            }}>
                <Box paddingTop={{md: '58px', xs: '58px'}} sx={{
                    position: 'relative',
                    minHeight: '313px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',
                    backgroundImage: `url(${breadcrumpImage})`,
                    backgroundRepeat: 'repeat'
                }}>
                    <Container style={{position: 'relative', zIndex: 2}}>
                        <Box>
                            <span style={{color: '#AED0EC'}}>Özellikler</span>
                        </Box>
                        <Box>
                            <h1 style={{color: '#424242', marginTop: '0', marginBottom: '7px'}}>Neler Sağlıyoruz?</h1>
                        </Box>
                        <Box sx={{marginBottom: '10px', maxWidth: '663px', color: '#424242'}}>
              <span>
                 {'Telepati\'nin eşsiz özelliklerini keşfedin.'}
              </span>
                        </Box>
                    </Container>
                </Box>


                <Box
                    component={'section'}
                    className={'section1'}
                    position={'relative'}
                    paddingBottom={{md: '70px', xs: '90px'}}>
                    <Container style={{position: 'relative', zIndex: 2, marginTop: '-80px'}}>
                        <Grid container xs={12} paddingLeft={{md: 0, xs: 5}} paddingRight={{md: 0, xs: 5}}>
                            <Grid item md={7} xs={12}>
                                <Box sx={{position: 'relative'}}>
                                    <img src={featuresImage1} style={{borderRadius: '15px'}}/>
                                    <Parallax speed={10}>
                                        <img src={featuresImage11} style={{
                                            borderRadius: '15px',
                                            position: 'absolute',
                                            right: '0',
                                            bottom: '120px'
                                        }}/>
                                    </Parallax>
                                </Box>
                            </Grid>
                            <Grid
                                item md={5} xs={12}
                                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Box style={{maxWidth: '562px', textAlign: 'left'}} marginTop={{md: 0, xs: 4}}>
                                    <Box>
                                        <BsShieldCheck style={{color: '#f44336', fontSize: '25px'}}/>
                                    </Box>
                                    <Typography
                                        variant="h4"
                                        color="#f44336"
                                        marginBottom={{md: '15px', xs: '10px'}}
                                        fontSize={{md: '30px', xs: '25px'}}
                                        sx={{
                                            fontWeight: 700,
                                            lineHeight: '41px'
                                        }}
                                    >
                                        {t('secureConnection')}
                                    </Typography>
                                    <Typography
                                        variant="body1" sx={{textAlign: 'left', color: '#000', fontWeight: 400}}
                                        fontSize={{md: '16px', xs: '14px'}}>
                                        {t('secureConnectionText1')}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{textAlign: 'left', color: '#000', fontWeight: 400, marginTop: '10px'}}
                                        fontSize={{md: '16px', xs: '14px'}}>
                                        {t('secureConnectionText2')}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                    <Box sx={{
                        height: '1px',
                        width: '60%',
                        bottom: 0,
                        left: 0,
                        opacity: '0.4',
                        backgroundColor: '#294687',
                        position: 'absolute'
                    }}></Box>
                </Box>

                <Box
                    component={'section'}
                    className={'section2'}
                    position={'relative'}
                    marginTop={{md: '150px', xs: '90px'}}
                    paddingBottom={{md: '70px', xs: '90px'}}>
                    <Container style={{position: 'relative', zIndex: 2, marginTop: '-80px'}}>
                        <Grid container xs={12} paddingLeft={{md: 0, xs: 5}} paddingRight={{md: 0, xs: 5}}>
                            <Grid
                                item md={5} xs={12}
                                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Box style={{maxWidth: '562px', textAlign: 'right'}} marginTop={{md: 0, xs: 4}}>
                                    <Box>
                                        <AiOutlineLike style={{color: '#f44336', fontSize: '25px'}}/>
                                    </Box>
                                    <Typography
                                        variant="h4"
                                        color="#f44336"
                                        marginBottom={{md: '15px', xs: '10px'}}
                                        fontSize={{md: '30px', xs: '25px'}}
                                        sx={{
                                            fontWeight: 700,
                                            lineHeight: '41px'
                                        }}
                                    >
                                        {t('flexibleUsage')}
                                    </Typography>
                                    <Typography
                                        variant="body1" sx={{textAlign: 'right', color: '#000', fontWeight: 400}}
                                        fontSize={{md: '16px', xs: '14px'}}>
                                        {t('flexibleUsageText1')}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{textAlign: 'right', color: '#000', fontWeight: 400, marginTop: '10px'}}
                                        fontSize={{md: '16px', xs: '14px'}}>
                                        {t('flexibleUsageText2')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={7} xs={12}>
                                <Box sx={{position: 'relative', textAlign: 'right'}}>
                                    <img src={featuresImage2} style={{borderRadius: '15px'}}/>
                                    <Parallax speed={10}>
                                        <img src={featuresImage22} style={{
                                            borderRadius: '15px',
                                            position: 'absolute',
                                            left: '0',
                                            bottom: '120px'
                                        }}/>
                                    </Parallax>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                    <Box sx={{
                        height: '1px',
                        width: '60%',
                        bottom: 0,
                        right: 0,
                        opacity: '0.4',
                        backgroundColor: '#294687',
                        position: 'absolute'
                    }}></Box>

                </Box>

                <Box
                    component={'section'}
                    className={'section3'}
                    position={'relative'}
                    marginTop={{md: '150px', xs: '90px'}}
                    paddingBottom={{md: '70px', xs: '90px'}}>
                    <Container style={{position: 'relative', zIndex: 2, marginTop: '-80px'}}>
                        <Grid container xs={12} paddingLeft={{md: 0, xs: 5}} paddingRight={{md: 0, xs: 5}}>
                            <Grid item md={7} xs={12}>
                                <Box sx={{position: 'relative'}}>
                                    <img src={featuresImage3} style={{borderRadius: '15px'}}/>
                                    <Parallax speed={10}>
                                        <img src={featuresImage33} style={{
                                            borderRadius: '15px',
                                            position: 'absolute',
                                            right: '0',
                                            bottom: '120px'
                                        }}/>
                                    </Parallax>
                                </Box>
                            </Grid>
                            <Grid
                                item md={5} xs={12}
                                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Box style={{maxWidth: '562px', textAlign: 'left'}} marginTop={{md: 0, xs: 4}}>
                                    <Box>
                                        <PiUsersFourLight style={{color: '#f44336', fontSize: '25px'}}/>
                                    </Box>
                                    <Typography
                                        variant="h4"
                                        color="#f44336"
                                        marginBottom={{md: '15px', xs: '10px'}}
                                        fontSize={{md: '30px', xs: '25px'}}
                                        sx={{
                                            fontWeight: 700,
                                            lineHeight: '41px'
                                        }}
                                    >
                                        {t('easyCommunication')}
                                    </Typography>
                                    <Typography
                                        variant="body1" sx={{textAlign: 'left', color: '#000', fontWeight: 400}}
                                        fontSize={{md: '16px', xs: '14px'}}>
                                        {t('easyCommunicationText1')}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{textAlign: 'left', color: '#000', fontWeight: 400, marginTop: '10px'}}
                                        fontSize={{md: '16px', xs: '14px'}}>
                                        {t('easyCommunicationText2')}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </>
    );
};

export default Features;
