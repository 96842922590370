import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import {Form} from './components';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

const Signup = (props) => {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, []);

    return (
        <Grid
            container
            minHeight={'calc(100vh)'}
            sx={{backgroundColor: '#F0F5FF'}}
        >

            <Grid
                item
                container
                alignItems={'center'}
                justifyContent={'center'}
                xs={0}
                md={0}
                backgroundColor="#F6F6F6">
                <Box display={'flex'} flexGrow={'1'} alignItems={'center'} height={'100%'}>
                    <Container maxWidth={'440px'}>
                        <Form/>
                    </Container>
                </Box>
            </Grid>
        </Grid>
    );
};

Signup.propTypes = {
    match: PropTypes.object
};

export default Signup;
