import React from 'react';
import {Box, Typography} from '@mui/material';
import callUser from '../svg/icons/call-user.svg';
import callOpen from '../svg/icons/call-open.svg';
import teamsBop from '../assets/mp3/teams_bop.mp3';
import callClose from '../svg/icons/call-close.svg';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {setIncomingCall, setOutGoingCall} from '../redux/actions';
import {Base64} from 'js-base64';

const CallNotification = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const loginUser = useSelector((state) => state.main.user);
    const {t, i18n} = useTranslation();
    const incomingCall = useSelector((state) => state.main.incomingCall);
    const outGoingCall = useSelector((state) => state.main.outGoingCall);
    const hubConnection = useSelector((state) => state.main.hubConnection);

    function replyToCall(status) {
        hubConnection.invoke('ReplyToCall', incomingCall.callerUserEmail, incomingCall.callerFullName, incomingCall.callID, status, incomingCall.meetingID);
        if (status == 1) {
            let hashedMeetinDetails = Base64.encode(`?meetingId=${incomingCall.meetingID}&userId=${loginUser.id}&userName=${loginUser.fullname}&email=${loginUser.email}`);
            history.push(`/${i18n.language}/meeting/go/${hashedMeetinDetails}`);
        }
        dispatch(setIncomingCall(null));
    }

    function cancelToCall() {
        hubConnection.invoke('CancelToCall', outGoingCall.callID);
        dispatch(setOutGoingCall(null));
    }

    return (
        <div>
            {incomingCall != null &&
                <Box width={{md: '324px', xs: '284px'}} style={{
                    background: 'rgba(106, 83, 184, 0.9)',
                    width: '324px',
                    height: '250px',
                    right: '20px',
                    bottom: '21px',
                    position: 'fixed',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '11px',
                    zIndex: 2,
                    padding: '20px'
                }}>
                    <audio style={{display: 'none'}} controls loop autoPlay>
                        <source src={teamsBop} type="audio/mpeg"/>
                    </audio>
                    <Box>
                        <Box mb={2}>
                            <Typography component={'span'} fontSize={'15px'} style={{color: '#fff'}}>
                                {incomingCall?.callerFullName} {t('calling')}
                            </Typography>
                        </Box>
                        <Box textAlign={'center'} position={'relative'} height={80} style={{paddingTop: '15px'}}>
                            <img className={'call-phone'} src={callUser} width={60} height={60}/>
                            <div className={'call-circle call-circle2'}></div>
                            <div className={'call-circle call-circle1'}></div>
                        </Box>
                        <Box textAlign={'center'} marginTop={3}>
                            <a
                                style={{marginRight: '11px', display: 'inline-block', cursor: 'pointer'}}
                                onClick={() => replyToCall(1)}>
                                <img src={callOpen} width={48} height={48}/>
                            </a>
                            <a
                                style={{marginLeft: '11px', display: 'inline-block', cursor: 'pointer'}}
                                onClick={() => replyToCall(2)}>
                                <img src={callClose} width={48} height={48}/>
                            </a>
                        </Box>
                    </Box>
                </Box>
            }
            {outGoingCall != null &&
                <Box width={{md: '324px', xs: '284px'}} style={{
                    background: 'rgba(106, 83, 184, 0.9)',
                    width: '324px',
                    height: '250px',
                    right: '20px',
                    bottom: '21px',
                    position: 'fixed',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '11px',
                    zIndex: 2,
                    padding: '20px'
                }}>
                    <audio style={{display: 'none'}} controls loop autoPlay>
                        <source src={teamsBop} type="audio/mpeg"/>
                    </audio>
                    <Box>
                        <Box mb={2}>
                            <Typography component={'span'} fontSize={'15px'} style={{color: '#fff'}}>
                                {outGoingCall.userName} {t('dialing')}
                            </Typography>
                        </Box>
                        <Box textAlign={'center'} position={'relative'} height={80} style={{paddingTop: '15px'}}>
                            <img className={'call-phone'} src={callUser} width={60} height={60}/>
                            <div className={'call-circle call-circle2'}></div>
                            <div className={'call-circle call-circle1'}></div>
                        </Box>
                        <Box textAlign={'center'} marginTop={3}>
                            <a
                                style={{marginLeft: '11px', display: 'inline-block', cursor: 'pointer'}}
                                onClick={() => cancelToCall()}>
                                <img src={callClose} width={48} height={48}/>
                            </a>
                        </Box>
                    </Box>
                </Box>
            }
        </div>
    );
};


export default CallNotification;
