import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {Button, Grid} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import {MeetingServices} from '../../../../../services';
import personIcon from '../../../../../svg/icons/person.svg';
import phoneComing from '../../../../../svg/icons/phone-coming.svg';
import phoneOnGoing from '../../../../../svg/icons/phone-ongoing.svg';
import phoneUnAnswered from '../../../../../svg/icons/phone-unanswered.svg';
import {useSelector} from 'react-redux';

const Calls = () => {
    const {t} = useTranslation();

    // eslint-disable-next-line no-unused-vars
    const [isAll, setIsAlL] = useState(true);
    const loginUser = useSelector((state) => state.main.user);
    const [callList, setCallList] = useState([]);
    const [tempCallList, setTempCallList] = useState([]);


    useEffect(() => {
        getList();
    }, []);

    useEffect(() => {
        if (isAll) {
            setTempCallList(callList);
        } else {
            let unAnsweredCalls = callList.filter(x => x.callStatus == 'Reddedildi');
            setTempCallList([...unAnsweredCalls]);
        }
    }, [isAll]);

    function getList() {
        MeetingServices.getMyCalls().then(response => {
            setCallList(response.data);
            setTempCallList(response.data);
        });
    }

    return (
        <Box>
            <Box marginBottom={7}>
                <Grid
                    container
                >
                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <Typography
                            variant="h5"
                            color="text.tertiary"
                            sx={{
                                fontWeight: 700,
                                textAlign: 'start'
                            }}
                        >
                            {t('calls')}
                        </Typography>
                    </Grid>


                    <Grid
                        item
                        md={8}
                        container
                        sx={{justifyContent: 'end'}}
                    >
                        <Button
                            style={{color: isAll ? '#362866' : '#9D8AD0', fontWeight: 600}}
                            onClick={() => setIsAlL(true)}> {t('all')}</Button>
                        <Button
                            style={{color: !isAll ? '#362866' : '#9D8AD0', fontWeight: 600}}
                            onClick={() => setIsAlL(false)}> {t('unanswered')}</Button>
                    </Grid>
                </Grid>

                <Grid container sx={{marginTop: 5, borderTop: '1px solid rgba(58,21,161,50%)'}}>

                    {tempCallList && tempCallList.map((item, i) => {

                        let callDate = new Date(item.created).toLocaleDateString() + ' ' + new Date(item.created).toLocaleTimeString();
                        let callStatusIcon = item.callStatus === 'Answered' ? item.callerUserId == loginUser.id ? phoneOnGoing : phoneComing : phoneUnAnswered;
                        let callStatusText = item.callStatus === 'Answered' ? item.callerUserId == loginUser.id ? t('outGoingCall') : t('comingCall') : t('missedCall');

                        return (
                            <Grid
                                key={i}
                                container
                                direction={'row'}
                                sx={{
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderBottom: '1px solid rgba(58,21,161,50%)',
                                }}
                            >

                                <Grid item md={9} style={{display: 'flex'}}>
                                    <Box component={'div'} style={{width: '50px', height: '50px'}}>
                                        <img src={personIcon} width={50} height={50}/>
                                    </Box>
                                    <Box flex={1} component={'div'} marginLeft={'10px'} paddingTop={'4px'}>
                                        <div>
                                            <strong>{item.callerUser.fullName}</strong>
                                        </div>

                                        <small
                                            style={{
                                                color: callStatusText !== t('missedCall') ? '#97acdb' : '#DC1115',
                                                lineHeight: '12px',
                                            }}
                                        >
                                            <img
                                                src={callStatusIcon} width={16}
                                                style={{verticalAlign: 'middle'}}/>

                                            {callStatusText}

                                        </small>
                                        <small
                                            style={{color: 'gray', lineHeight: '12px',}}
                                        >
                                            {' - ' + callDate}
                                        </small>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    textAlign={'right'}
                                >
                                    <Button
                                        disabled={true}
                                        color={'primary'}
                                        style={{borderRadius: '50%', width: '45px', height: '45px', minWidth: '45px'}}
                                        variant={'contained'}>
                                        <PhoneIcon/>
                                    </Button>
                                </Grid>
                            </Grid>
                        );
                    })}

                    {callList.length == 0 &&

                        <Grid
                            container
                            direction={'row'}
                            sx={{
                                paddingTop: 2,
                                paddingBottom: 2,
                                borderBottom: '1px solid rgba(58,21,161,50%)',
                                justifyContent: 'center'
                            }}
                        >
                            {t('noCallHistory')}
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    );
};

export default Calls;
