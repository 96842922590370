import React from 'react';
import {useSelector} from 'react-redux';

const CurrencySymbol = () => {
    const isDollar = useSelector((state) => state.main.isCurrencyDollar);

    return (
        <span>
      {isDollar ? '$' : '₺'}
    </span>
    );
};

export default CurrencySymbol;
