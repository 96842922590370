import React, {useEffect, useState} from 'react';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import {useFormik} from 'formik';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import bgContactUs from '../../../svg/bg/bg-contact-us.png';
import Typography from '@mui/material/Typography';
import BgBottomFigure from '../../../common/BgBottomFigure';
import {useLocation} from 'react-router-dom';
import {AccountServices} from 'services';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';

const ContactUs = () => {
    const {t} = useTranslation();
    let location = useLocation();
    const [initialValues] = useState({
        subject: '',
        email: '',
        message: '',
        name: '',
    });

    const validationSchema = yup.object().shape({
        subject: yup
            .string()
            .min(5, t('validMin5'))
            .required(t('requiredField')),
        email: yup
            .string()
            .trim()
            .matches(/^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}$/i, t('validEmail'))
            .required(t('requiredField')),
        message: yup
            .string()
            .min(10, t('validMin10'))
            .required(t('requiredField')),
        name: yup
            .string()
            .trim()
            .matches(/^[a-zA-ZğüşöçİĞÜŞÖÇı]+( [a-zA-ZğüşöçİĞÜŞÖÇı]+)+$/, t('validName'))
            .required(t('requiredField'))
    });

    const [loading, setLoading] = useState(false);
    const onSubmit = (values) => {
        setLoading(true);
        let sentValues = {...values};
        AccountServices.sendContactUs(sentValues).then((response) => {
            let timerInterval;
            if (response) {
                Swal.fire({
                    icon: 'success',
                    title: response.data.title,
                    timer: 4000,
                    timerProgressBar: true,
                    showCloseButton: false,
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: t('ok'),
                    text: response.data.content,
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then(() => {
                    formik.resetForm();
                    setLoading(false);
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: t('error'),
                    timer: 4000,
                    timerProgressBar: true,
                    showCloseButton: false,
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: t('ok'),
                    text: response.data.content,
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then(() => {
                    setLoading(false);
                });
            }

        });
    };
    const formik = useFormik({
        initialValues,
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema: validationSchema,
        onSubmit
    });
    const query = new URLSearchParams(location.search);
    const [subjectDisable, setSubjectDisable] = useState(false);

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        const q = query.get('type');

        if (q && q === 'enterprise') {
            formik.setFieldValue('subject', t('enterPrisePackageBuy'));
            setSubjectDisable(true);
        } else {
            formik.setFieldValue('subject', '');
            setSubjectDisable(false);
        }
    }, [location]);

    return (
        <>
            <Box paddingTop={{md: '50px', xs: '30px'}} sx={{
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingBottom: '80px',
                minHeight: 'calc(100vh - 132px)',
                backgroundImage: `url(${bgContactUs})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                zIndex: 2
            }}>
                <Box paddingTop={{md: '51px', xs: '40px'}} style={{
                    maxWidth: '812px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingBottom: '75px',
                    boxShadow: '0px 3px 6px #00000029',
                    width: '100%',
                    border: '1px solid #D9D9D9',
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#fff',
                    borderRadius: '41px'
                }}>

                    <form onSubmit={formik.handleSubmit} style={{maxWidth: '536px'}}>
                        <Grid marginTop={{md: '30px', sm: '0'}} container item xs={12}>
                            <Typography
                                variant="h5"
                                color="#3F3F3F"
                                textAlign={'left'}
                                marginBottom={{md: 5, xs: '20px'}}
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '32px'
                                }}
                            >
                                {t('footerContactUs')}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            alignItems={{xs: 'stretched', sm: 'stretched'}}
                            justifyContent="center"
                        >

                            {/* ad */}
                            {
                                <Grid item xs={12}>
                                    <TextField
                                        autoComplete="false"
                                        variant="outlined"
                                        label={t('fullName')}
                                        fullWidth
                                        name={'name'}
                                        value={formik.values.name}
                                        sx={{'label': {color: '#b4b4b4', fontWeight: 600}}}
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </Grid>
                            }

                            {/* eposta */}
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="false"
                                    variant="outlined"
                                    fullWidth
                                    label={t('email')}
                                    name={'email'}
                                    value={formik.values.email}
                                    sx={{'label': {color: '#b4b4b4', fontWeight: 600}}}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>

                            {/* Konu */}
                            {
                                <Grid item xs={12}>
                                    <TextField
                                        autoComplete="false"
                                        variant="outlined"
                                        fullWidth
                                        label={t('messageSubject')}
                                        disabled={subjectDisable}
                                        name={'subject'}
                                        value={formik.values.subject}
                                        sx={{'label': {color: '#b4b4b4', fontWeight: 600}}}
                                        onChange={formik.handleChange}
                                        error={formik.touched.subject && Boolean(formik.errors.subject)}
                                        helperText={formik.touched.subject && formik.errors.subject}
                                    />
                                </Grid>
                            }

                            {/* message */}
                            {
                                <Grid item xs={12}>
                                    <TextField
                                        autoComplete="false"
                                        variant="outlined"
                                        fullWidth
                                        name={'message'}
                                        label={t('contactUsEnterMessage')}
                                        multiline
                                        rows={6}
                                        value={formik.values.message}
                                        sx={{'label': {color: '#b4b4b4', fontWeight: 600}}}
                                        onChange={formik.handleChange}
                                        error={formik.touched.message && Boolean(formik.errors.message)}
                                        helperText={formik.touched.message && formik.errors.message}
                                    />
                                </Grid>
                            }

                            <Grid container item xs={12} sx={{paddingTop: '2px !important'}} justifyContent={'end'}>
                                <Box
                                    display="flex"
                                    justifyContent={'flex-end'}
                                    sx={{marginTop: 5}}
                                >
                                    <LoadingButton
                                        color={'primary'} size={'large'} variant={'contained'} type={'submit'}
                                        disabled={loading}
                                        loading={loading}
                                        sx={{fontWeight: 500, width: 150,}}>
                                        {t('contactUsSendMessage')}
                                    </LoadingButton>
                                </Box>
                            </Grid>

                        </Grid>
                    </form>

                </Box>
            </Box>
            <BgBottomFigure style={{marginTop: '-200px'}}/>
        </>
    );
};

export default ContactUs;
