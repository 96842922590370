/* eslint-disable react/no-unescaped-entities */
import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Link, SvgIcon} from '@mui/material';
import {AccountServices} from 'services/index';
import {ReactComponent as SuccessBadge} from 'svg/misc/successBadge.svg';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';

const Form = () => {
    const {t} = useTranslation();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const initialValues = {
        eMail: ''
    };

    const validationSchema = yup.object({
        eMail: yup
            .string(t('validMail'))
            .trim()
            .email(t('validMail'))
            .required(t('requiredField'))
    });

    const onSubmit = (values) => {
        resetPassword(values);
        return values;
    };
    const resetPassword = (data) => {
        setLoading(true);
        var promise = AccountServices.resetPassword(data);
        promise
            .then((response) => {
                setLoading(false);
                if (!response) return;
                setSuccess(true);

                //push route change
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    };
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit
    });

    return success ?
        (<Box textAlign={'center'}>
            <Box display={'flex'} justifyContent={'center'} marginBottom={10}>
                <SvgIcon htmlColor={'#4A4B4B'} sx={{transform: 'scale(6)'}} component={SuccessBadge}
                         viewBox="0 0 156 156"/>
            </Box>
            <Typography
                variant="h4"
                color="text.tertiary"
                sx={{
                    fontWeight: 700
                }}
            >
                {t('success')}
            </Typography>
            <Typography color="text.tertiary" marginY={2}>
                {t('successMessage')}
            </Typography>
            <Button
                component={Link}
                color={'primary'}
                size={'large'}
                variant={'contained'}
                href="/login"
                underline={'none'}

            >
                {t('ok')}
            </Button>
        </Box>)
        : (
            <Box>
                <Box marginBottom={4} textAlign={'center'}>
                    <Typography
                        variant="h5"
                        color="text.tertiary"
                        sx={{
                            fontWeight: 700
                        }}
                    >
                        {t('accountPasswordReset')}
                    </Typography>
                    <Typography color="text.tertiary" marginTop={2}
                                sx={{fontSize: {xs: '12px', md: '14px'}, marginTop: '5px'}}>
                        {t('accountPasswordResetText')}
                    </Typography>
                </Box>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label={t('registerEmail')}
                                variant="outlined"
                                name={'eMail'}
                                fullWidth
                                value={formik.values.eMail}
                                onChange={formik.handleChange}
                                error={formik.touched.eMail && Boolean(formik.errors.eMail)}
                                helperText={formik.touched.eMail && formik.errors.eMail}
                            />
                        </Grid>
                        <Grid item container xs={12}>
                            <Box
                                display="flex"
                                flexDirection={{xs: 'column', sm: 'row'}}
                                alignItems={{xs: 'stretched', sm: 'center'}}
                                justifyContent={'center'}
                                width={'100%'}
                                maxWidth={600}
                                margin={'0 auto'}
                            >

                                <LoadingButton
                                    size={'large'} loading={loading} disabled={loading} variant={'contained'}
                                    type={'submit'}
                                    sx={{padding: '10px 50px', borderRadius: '10px'}}>
                                    {t('contactUsSendMessage')}
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        );
};

export default Form;
