import {Ajax} from '../utils/index';

export default {
    startTrial(model) {
        let promise = Ajax.postPromise('/payment/start-trial', model);
        return promise;
    },
    paymentRequest(selected) {
        let promise = Ajax.postPromise('/payment/pay', selected);
        return promise;
    },
    getAllPackages(currency) {
        let promise = Ajax.getPromise('/payment/get-all-products/' + currency);
        return promise;
    },
    getCurrentProduct() {
        let promise = Ajax.getPromise('/payment/get-current-product');
        return promise;
    },
    cancelPayment(id, invoiceNo) {
        let promise = Ajax.getPromise('/payment/cancel-payment/' + id + '/' + invoiceNo);
        return promise;
    }
};
