import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import {Button, TextField, Typography} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs: '95%', md: 600},
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 2
};

const DisplayNameModal = ({visible, setVisible, setDisplayName, displayEmail, setDisplayEmail}) => {
    const {t} = useTranslation();

    const initialValues = {
        name: '',
        email: ''
    };

    // eslint-disable-next-line no-useless-escape
    var validationSchema = yup.object({
        // eslint-disable-next-line
        name: yup.string(t('validName')).matches(/^[a-zA-ZğüşöçİĞÜŞÖÇı\[\]() ]+$/, t('validName')).trim().min(2, t('validName')).max(40, t('validName')).required(t('requiredField')),
        email: yup.string(t('validMail')).trim().email(t('validMail')).when('emailVisible', {
            is: true,
            then: yup.string(t('validMail')).required(t('requiredField'))
        })
    });

    const onSubmit = (values) => {
        setDisplayName(values.name);
        setDisplayEmail(values.email);
    };

    const formik = useFormik({initialValues, validationSchema: validationSchema, onSubmit});

    return (
        <Modal open={visible}>
            <form onSubmit={formik.handleSubmit}>
                <Box sx={style}>
                    <Box sx={{textAlign: 'end', height: 60}}>
                        <IconButton onClick={() => setVisible(false)}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            height: 3,
                            width: {md: '105.6%', xs: '109.6%'},
                            backgroundColor: '#3A15A1',
                            marginLeft: -2
                        }}
                    />
                    <Typography
                        textAlign={'center'}
                        fontWeight={700}
                        color={'#4A4B4B'}
                        variant="h4"
                        mt={6}
                        mb={6}
                    >
                        {t('guestParticipant')}
                    </Typography>

                    <Grid container justifyContent={'center'}>
                        <Grid item md={9}>
                            <TextField
                                name={'name'}
                                value={formik.values.name}
                                onChange={e => formik.setFieldValue('name', e.target.value)}
                                label={t('visibleName')}
                                fullWidth
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                    </Grid>

                    {!displayEmail && <Grid container justifyContent={'center'} marginTop={2}>
                        <Grid item md={9}>
                            <TextField
                                name={'email'}
                                value={formik.values.email}
                                onChange={e => formik.setFieldValue('email', e.target.value)}
                                label={t('accountEmail')}
                                fullWidth
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                    </Grid>}

                    <Box display="flex" sx={{marginTop: 3, marginBottom: 5, justifyContent: 'center'}}>
                        <InfoIcon sx={{color: 'red', marginRight: 1}}/>
                        <Typography
                            color={'#454545'}
                            fontSize={14}
                        >
                            {t('meetingKvkk')}
                        </Typography>
                    </Box>

                    <Box sx={{textAlign: 'center', marginBottom: 5}}>
                        <Button
                            sx={{width: 140, fontWeight: 500}}
                            size={'large'}
                            variant={'contained'}
                            type="submit"
                        >
                            {t('joinMeeting')}
                        </Button>
                    </Box>
                </Box>
            </form>
        </Modal>
    );
};

DisplayNameModal.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    setDisplayName: PropTypes.func,
    displayEmail: PropTypes.string,
    setDisplayEmail: PropTypes.func
};

export default DisplayNameModal;
