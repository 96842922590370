import React from 'react';
import {useTheme} from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from 'common/Container';
import NotFoundIllustration from 'svg/illustrations/NotFound';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const NotFound = () => {
    const theme = useTheme();
    const history = useHistory();
    const {t} = useTranslation();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <Box
            minHeight={'calc(100vh - 160px)'}
            height={'100%'}
            display={'flex'}
            alignItems={'center'}
            bgcolor={theme.palette.alternate.main}
        >
            <Container>
                <Grid container spacing={6}>
                    <Grid item container justifyContent={'center'} xs={12} md={6}>
                        <Box
                            height={'100%'}
                            width={'100%'}
                            maxWidth={{xs: 500, md: '100%'}}
                        >
                            <NotFoundIllustration width={'100%'} height={'100%'}/>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        container
                        alignItems={'center'}
                        justifyContent={'center'}
                        xs={12}
                        md={6}
                    >
                        <Box>
                            <Typography
                                variant="h1"
                                component={'h1'}
                                align={isMd ? 'left' : 'center'}
                                sx={{fontWeight: 700, color: 'white'}}
                            >
                                404
                            </Typography>
                            <Typography
                                variant="h6"
                                component="p"
                                color={'#bbbbbb'}
                                align={isMd ? 'left' : 'center'}
                            >
                                {t('page404Message')}
                                <br/>
                                {t('page404Message2')}{' '}
                                <Link onClick={() => history.push('/contact-us')} underline="none"
                                      style={{color: 'white', cursor: 'pointer'}}>
                                    {t('page404TellUs')}
                                </Link>
                            </Typography>
                            <Box
                                marginTop={4}
                                display={'flex'}
                                justifyContent={{xs: 'center', md: 'flex-start'}}
                            >
                                <Button
                                    component={Link}
                                    variant="contained"
                                    color="warning"
                                    size="large"
                                    onClick={() => history.push('/')}
                                >
                                    {t('page404BackHome')}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default NotFound;
