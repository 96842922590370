import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import {Link, useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import LogoS from 'svg/logos/Telepati_Logo_Design.png';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import UserDropdown from '../UserDropdown/UserDropdown';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';


const Topbar = ({onSidebarOpen}) => {
    const {t, i18n} = useTranslation();
    let history = useHistory();
    const user = useSelector((state) => state.main.user);
    const isAuthenticated = useSelector((state) => state.main.isAuthenticated);

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
            padding={{xs: '0 16px', md: '0 24px'}} // Responsive padding
        >
            <Box display={'flex'} alignItems={'center'}>
                <Box
                    display={'flex'}
                    alignItems="center"
                    component="a"
                    underline="none"
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center', // Center items vertically
                        marginRight: i18n.language ? '0' : 0
                    }}
                    onClick={() => history.push('/')}
                    height={60}
                >
                    <img src={LogoS} height={50} alt=""/>
                    <Typography
                        variant="h6"
                        component="span"
                        sx={{
                            marginLeft: 1,
                            fontWeight: 'bold',
                            color: '#b8c0c0',
                            display: 'flex',
                            alignItems: 'center' // Center text vertically
                        }}
                    >
                        Telepati
                    </Typography>
                </Box>
            </Box>


            <Box display="flex" alignItems="center" marginLeft="auto">

                <Box sx={{display: {xs: 'none', md: 'flex'}}} marginRight={2}>
                    <Link
                        to="/contact-us"
                        style={{
                            width: 73,
                            paddingRight: '50px',
                            color: '#b8c0c0',
                            textDecoration: 'none',
                            fontSize: '0.875rem' // Adjust font size as needed
                        }}
                    >
                        {t('tecnicalSupport')}
                    </Link>
                </Box>

                <Box sx={{display: {xs: 'none', md: 'flex'}}} marginRight={2}>
                    <LanguageSwitcher/>
                </Box>

                {isAuthenticated ? (
                    <Box sx={{display: {xs: 'none', md: 'flex'}, alignItems: 'center', marginTop: '4px'}}>
                        <UserDropdown user={user}/>
                    </Box>
                ) : (
                    <Link
                        to={'/'}
                    >
                    </Link>
                )}

                <Box marginRight={{xs: 1, sm: 2}} sx={{display: {xs: 'flex', md: 'none'}}}>
                    <IconButton
                        onClick={onSidebarOpen} aria-label="Menu"
                        sx={{backgroundColor: 'transparent', color: '#b8c0c0'}}
                    >
                        <MenuIcon style={{fontSize: '30px'}}/>
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

Topbar.propTypes = {
    onSidebarOpen: PropTypes.func
};

export default Topbar;
