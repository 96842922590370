import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import ChangePassword from 'views/authPages/ChangePassword/ChangePassword';
import BuyPackage from 'views/supportingPages/Pricing/BuyPackage/BuyPackage';
import TrialStart from 'views/supportingPages/Pricing/TrialStart/TrialStart';
import ContactUs from 'views/supportingPages/ContactUs';
import WithLayout from 'WithLayout';
import {BodyMain as Body, Main} from '../layouts/index';
import {ForgotPassword, Login, Signup} from '../views/authPages';
import {Home} from '../views/landingPages';
import {MeetingGo, NotFound, Pricing, PrivacyPolicy, Profile} from '../views/supportingPages';
import AuthRoute from './authRoute';
import SalesPolicy from '../views/supportingPages/Policy/SalesPolicy';
import Features from '../views/supportingPages/Features';

const Routes = () => {
    return (
        <Switch>
            <Route
                exact
                path="/privacy-policy"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={PrivacyPolicy}
                        layout={Main}
                    />
                )}
            />
            <Route
                exact
                path="/sales-policy"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={SalesPolicy}
                        layout={Main}
                    />
                )}
            />
            <Route
                exact
                path="/"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={Login}
                        layout={Main}
                    />
                )}
            />
            <Route
                exact
                path="/account/company-approval"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={Home}
                        layout={Main}
                    />
                )}
            />
            <Route
                exact
                path="/:lang/meeting/go/:meetingId"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={MeetingGo}
                        layout={Main}
                    />
                )}
            />
            <Route
                exact
                path="/pricing"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={Pricing}
                        layout={Main}
                    />
                )}
            />
            <AuthRoute
                path="/pricing/trial-start"
                type="private"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={TrialStart}
                        layout={Main}
                    />
                )}
            />
            <AuthRoute
                path="/pricing/buypackage"
                type="private"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={BuyPackage}
                        layout={Main}
                    />
                )}
            />
            <Route
                exact
                path="/login"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={Login}
                        layout={Body}
                    />

                )}>
            </Route>

            <Route
                exact
                path="/contact-us"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={ContactUs}
                        layout={Main}
                    />
                )}
            />

            <Route
                exact
                path="/features"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={Features}
                        layout={Main}
                    />
                )}
            />

            <AuthRoute
                exact
                path="/:lang/account/accountverification"
                type="guest"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={Login}
                        layout={Body}
                    />

                )}>
            </AuthRoute>

            <Route
                path="/:lang/account/password-verification-check"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={ChangePassword}
                        layout={Body}
                    />
                )}
            />

            <AuthRoute
                type="guest"
                exact
                path="/signup"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={Signup}
                        layout={Body}
                    />
                )}
            />

            <AuthRoute
                type="guest"
                exact
                path="/page-forgot-password-simple"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={ForgotPassword}
                        layout={Main}
                    />
                )}
            />
            <AuthRoute
                type="private"
                path="/profile/:title"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={Profile}
                        layout={Main}
                    />
                )}
            />
            <AuthRoute
                type="private"
                exact
                path="/profile"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={Profile}
                        layout={Main}
                    />
                )}
            />
            <Route
                exact
                path="/page-not-found"
                render={(matchProps) => (
                    <WithLayout
                        {...matchProps}
                        component={NotFound}
                        layout={Main}
                    />
                )}
            />
            <Redirect to="/page-not-found"/>
        </Switch>
    );
};

export default Routes;
