import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import {SidebarNav} from './components';

const Sidebar = (props) => {
    const {open, variant, onClose, ...rest} = props;

    return (
        <Drawer
            anchor="right"
            hideBackdrop={true}
            onClose={() => onClose()}
            open={open}
            variant={variant}
            sx={{

                '& .MuiPaper-root': {
                    width: '100%',
                    maxWidth: {xs: '100%', sm: 400},
                },
            }}
        >
            <Box
                {...rest}
                sx={{
                    height: '100%',
                    padding: '0',
                    backgroundColor: '#f7f7f8'
                }}
            >
                <SidebarNav onClose={onClose}/>
            </Box>
        </Drawer>
    );
};

Sidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
};

export default Sidebar;
