import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import {Footer, Sidebar, Topbar} from './components';
import Container from 'common/Container';
import store from '../../redux/store';
import {setHubConnection, setIncomingCall, setOnlineUsers, setOutGoingCall, setPackages} from '../../redux/actions';
import {useTranslation} from 'react-i18next';
import {PricingServices} from '../../services';
import {useDispatch, useSelector} from 'react-redux';
import CallNotification from '../../common/CallNotification';
import {useCookies} from 'react-cookie';
import {HttpTransportType, HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import {useHistory} from 'react-router-dom';
import CallUnAnsweredNotification from '../../common/CallUnAnsweredNotification';
import LogoRenkli from '../../svg/logos/LogoRenkli.png';
import {Base64} from 'js-base64';

const Main = ({children, themeToggler, themeMode, setThemePalette, paletteType}) => {
    const {i18n, t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [openSidebar, setOpenSidebar] = useState(false);
    const [unAnsweredModal, setUnAnsweredModal] = useState(false);
    const user = useSelector((state) => state.main.user);
    const loginUser = useSelector((state) => state.main.user);
    const packages = useSelector((state) => state.main.packages);
    const isDollar = useSelector((state) => state.main.isCurrencyDollar);
    const isAuthenticated = useSelector((state) => state.main.isAuthenticated);
    const currentProduct = useSelector((state) => state.main.currentProduct);
    // eslint-disable-next-line no-unused-vars
    const [cookie, setCookie] = useCookies(['token']);
    const hubConnection = useSelector((state) => state.main.hubConnection);

    useEffect(() => {
        setDefaultLang();
        if (isAuthenticated && currentProduct && currentProduct.title != 'Default') {
            connectSocketHub();
        }
    }, []);


    useEffect(() => {
        getAllProduct();
    }, [isDollar]);

    function setDefaultLang() {
        if (localStorage.getItem('lngChnged') !== 'true') {
            localStorage.setItem('i18nextLng', 'tr');
        }
    }

    function getAllProduct() {
        PricingServices.getAllPackages(isDollar).then((res) => {
            if (res) {
                let localPackages = [...packages];

                // API property'lerini local details id'lerine bağlayan mapping
                const propertyMapping = {
                    isCreateQuicklyMeeting: 'createMeetingForLater',
                    isPlanMeeting: 'assignMeetingAtendeeType',
                    meetingRecording: 'recordMeeting',
                    meetingNoteCreation: 'useNotes',
                    screenShare: 'useScreenShare',
                    inChatMeeting: 'useGeneralChat',
                    useWhiteboard: 'useWhiteboard'
                };

                res.data.forEach((apiPackage) => {
                    // Standart, Business ve diğer paketler için doğru eşleşme yap
                    let localPackage = localPackages.find(
                        (x) =>
                            x.title.toLowerCase() === apiPackage.title.toLowerCase() ||
                            (apiPackage.title.toLowerCase() === 'standard' &&
                                x.id === 'standart') // 'Standard' ile 'standart' eşleştiriliyor
                    );

                    if (localPackage) {
                        const price = parseFloat(apiPackage.price.replace(',', '.'));
                        localPackage.monthlyAmount = price.toFixed(2);
                        localPackage.annualAmount = (price * 12).toFixed(2);

                        // Boolean özellikleri güncelle
                        localPackage.details.forEach((detail) => {
                            const apiField = propertyMapping[detail.id];
                            if (
                                apiField &&
                                apiPackage[apiField] !== undefined &&
                                apiPackage[apiField] !== null
                            ) {
                                detail.value = !!apiPackage[apiField];
                            }
                        });
                    }
                });

                // Güncellenmiş paketleri dispatch et
                store.dispatch(setPackages(localPackages));
            }
        });
    }

    // eslint-disable-next-line no-unused-vars
    async function connectSocketHub() {
        if (isAuthenticated && cookie && user) {
            let hubCon = new HubConnectionBuilder()
                .withUrl(process.env.REACT_APP_BACKEND_URL + 'hubs/videocall', {
                    accessTokenFactory: () => cookie.token,
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets
                })
                .withAutomaticReconnect([0, 1000, 5000, null])
                .configureLogging(LogLevel.Error)
                .build();

            hubCon.serverTimeoutInMilliseconds = 86400000;

            await hubCon
                .start()
                .then(() => console.log('SignalR Connected'))
                .catch((err) => console.error('SignalR Connection Error:', err));

            hubCon.onclose(() => {
                console.warn('SignalR connection closed.');
                resetSocketState();
            });

            hubCon.onreconnecting(() => {
                console.warn('SignalR reconnecting...');
                resetSocketState();
            });

            hubCon.onreconnected(() => {
                console.log('SignalR reconnected.');
            });

            // Video Aramalarını Dinlemek için
            hubCon.on('CallBroadcast', (result) => {
                console.log('CallBroadcast:', result);
                //Status 0 = Arama yapıldı
                if (result.status === 0) {
                    dispatch(setIncomingCall(result));
                    CheckPageStatus(result.callerFullName, result.meetingID);
                }

                //Status 1 = Arama yanıtlandı
                if (result.status === 1) {
                    let hashedMeetinDetails = Base64.encode(`?meetingId=${result.meetingID}&userId=${loginUser.id}&userName=${loginUser.fullname}&email=${loginUser.email}`);
                    history.push(`/${i18n.language}/meeting/go/${hashedMeetinDetails}`);
                    dispatch(setIncomingCall(null));
                    dispatch(setOutGoingCall(null));
                }

                //Status 2 = Arama reddedildi
                if (result.status === 2) {
                    // Eğer arayan kişi çağrıyı kapatmışsa popup göstermemek için yazıldı
                    if (loginUser.email === result.callerUserEmail) {
                        setUnAnsweredModal(true);
                    }
                    dispatch(setIncomingCall(null));
                    dispatch(setOutGoingCall(null));
                }
            });

            // Online User Listesini Dinlemek için
            hubCon.on('OnlineUserBroadcast', (users) => {
                dispatch(setOnlineUsers(users)); // Redux veya state'e kaydet
            });

            // Hub bağlantısını Redux'a kaydet
            dispatch(setHubConnection(hubCon));
        }
    }

    function resetSocketState() {
        dispatch(setOnlineUsers([]));
        dispatch(setIncomingCall(null));
        dispatch(setOutGoingCall(null));
    }

    // eslint-disable-next-line no-unused-vars
    function CheckPageStatus(user, meetingID) {
        if (!('Notification' in window)) {
            console.log('This browser does not support system notifications!');
        } else if (Notification.permission === 'granted') {
            sendNotification(user, meetingID);
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission((permission) => {
                if (permission === 'granted') {
                    sendNotification(user, meetingID);
                }
            });
        }
    }

    // eslint-disable-next-line no-unused-vars
    function sendNotification(user, meetingID) {
        if (document.hidden) {
            const notification = new Notification(t('telepatiVideoCall'), {
                icon: LogoRenkli,
                body: `${user}${t('calling')}`
            });
            notification.onclick = () => {
                window.focus();
            };
        }
    }

    return (
        <div style={{direction: i18n.language === 'ar' ? 'rtl' : 'ltr'}}>
            <AppBar
                position={'fixed'}
                elevation={1}
                sx={{
                    background: '#fff',
                    borderBottom: '1px solid #F2EEFF',
                    borderBottomLeftRadius: {md: 20, xs: 10},
                    borderBottomRightRadius: {md: 20, xs: 10}
                }}
            >
                <Container paddingY={{xs: 3 / 4, sm: 2}}>
                    <Topbar
                        onSidebarOpen={() => setOpenSidebar(true)}
                        themeMode={themeMode}
                        themeToggler={themeToggler}
                        setThemePalette={setThemePalette}
                        paletteType={paletteType}
                    />
                </Container>
            </AppBar>
            <Sidebar
                onClose={() => setOpenSidebar(false)}
                open={openSidebar}
                variant="temporary"
            />
            <main>
                <Box
                    sx={{
                        marginTop: '70px',
                        minHeight: 'calc(100vh - 160px)',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    {children}
                </Box>
            </main>
            <Box
                sx={{
                    background: '#FFFFFF',
                    boxShadow: '0px -15px 71px rgba(78, 40, 208, 0.03)'
                }}
                style={{position: 'relative', zIndex: 2}}
            >
                <Container paddingY={2}>
                    <Footer/>
                </Container>
            </Box>
            <CallNotification/>
            {unAnsweredModal && (
                <CallUnAnsweredNotification setShowModal={setUnAnsweredModal}/>
            )}
        </div>
    );
};

Main.propTypes = {
    children: PropTypes.node,
    themeToggler:
    PropTypes.func.isRequired,
    themeMode:
    PropTypes.string.isRequired,
    setThemePalette:
    PropTypes.func.isRequired,
    paletteType:
    PropTypes.string.isRequired
};

export default Main;
