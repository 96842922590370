import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Turkish from 'locales/tr';
import English from 'locales/en';
import Arabic from 'locales/ar';
import Russian from 'locales/ru';

const resources = {
    tr: {
        translation: Turkish
    },
    en: {
        translation: English
    },
    ar: {
        translation: Arabic
    },
    ru: {
        translation: Russian
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'tr',
        supportedLngs: ['tr', 'en', 'ar', 'ru'],
        debug: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
