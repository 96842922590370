import {responsiveFontSizes} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import shadows from './shadows';
import palette from './palette';


const getTheme = (mode, paletteType) =>
    responsiveFontSizes(
        createTheme({
            palette: palette('light', paletteType),

            layout: {
                contentWidth: 1236,
            },
            shadows: shadows(mode),
            typography: {
                fontFamily: '"Open Sans", sans-serif',
                button: {
                    textTransform: 'none',
                    fontWeight: 'medium',
                },
            },
            zIndex: {
                appBar: 1200,
                drawer: 1300,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        label: {
                            fontWeight: 600,
                        },
                        containedSecondary: mode === 'light' ? {color: 'white'} : {},
                    },
                },
                MuiIconButton: {
                    root: {
                        '&:hover': {
                            backgroundColor: 'white'
                        }
                    }
                },
                MuiSelect: {
                    styleOverrides: {
                        root: {
                            color: '#424242',
                            backgroundColor: '#fff',
                            borderRadius: '10px',
                            '& :-webkit-autofill': {
                                'WebkitBoxShadow': '0 0 0 100px #dfd7ef inset',
                            },
                            '& fieldset': {
                                borderColor: '#424242 !important'
                            },
                            '& fieldset:hover': {
                                borderColor: '#424242 !important'
                            },
                        }
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            color: '#424242',
                            backgroundColor: '#fff',
                            borderRadius: '10px',
                            '& :-webkit-autofill': {
                                'WebkitBoxShadow': '0 0 0 100px #dfd7ef inset',
                            },
                            '& fieldset': {
                                borderColor: '#424242 !important'
                            },
                            '& fieldset:hover': {
                                borderColor: '#424242 !important'
                            },
                        }
                    },
                },
                MuiFormHelperText: {
                    styleOverrides: {
                        root: {
                            marginLeft: 0,
                            '&:before': {
                                content: '"\\0021"',
                                width: '1.5em',
                                height: '1.5em',
                                color: 'white',
                                paddingLeft: '0.6em',
                                marginRight: '0.5em',
                                display: 'inline-block',
                                fontSize: '0.8em',
                                backgroundColor: '#d32f2f',
                                borderRadius: '50px',
                            }
                        }
                    },
                },
                MuiAccordionSummary: {
                    styleOverrides: {
                        root: {
                            padding: '50px',
                            '&.Mui-expanded': {
                                boxShadow: '0 2px 12px 0 rgba(0,0,0,.11)'
                            }
                        },
                    }
                },
            },
        }),
    );

export default getTheme;
