export const Contracts = {
    KvkkText: `YD YAZILIM VE TEKNOLOJİ A.Ş.</br>
Kişisel Verilerin Korunması Aydınlatma Metni<br><br>
YD YAZILIM VE TEKNOLOJİ A.Ş. kişisel verilerinizin hukuka uygun olarak toplanması, saklanması ve paylaşılmasını sağlamak ve gizliliğinizi korumak amacıyla mümkün olan en üst seviyede güvenlik tedbirlerini almaktadır.
Amacımız; 6698 sayılı Kişisel Verilerin Korunması Kanununun 10. maddesi gereğince ve sizlerin memnuniyeti doğrultusunda, kişisel verilerinizin alınma şekilleri, işlenme amaçları, paylaşılan kişiler, hukuki nedenleri ve haklarınız konularında sizi en şeffaf şekilde bilgilendirmektir.
<br><br>
a) Veri Sorumlusu</br>
6698 sayılı Kişisel Verilerin Korunması Kanunu (“6698 sayılı Kanun”) uyarınca, kişisel verileriniz; veri sorumlusu olarak YD YAZILIM VE TEKNOLOJİ A.Ş. (“ŞİRKET”) tarafından aşağıda açıklanan kapsamda toplanacak ve işlenebilecektir.
<br><br>
b) Kişisel Verilerin Hangi Amaçla İşleneceği</br>
ŞİRKET tarafından, müşterileri, internet sitesi üyeleri, çalışanları, potansiyel müşterileri, çalışan adayları, iş ortakları ve tedarikçileri gibi taraflardan, kimlik bilgisi, iletişim bilgisi, müşteri bilgisi, müşteri işlem bilgisi, işlem güvenliği bilgisi, hukuki işlem ve uyum bilgisi ile pazarlama satış bilgisi gibi kategorilerde kişisel veri toplanabilmektedir.
Toplanan kişisel verileriniz;
</br>- ŞİRKET ürün ve hizmetlerinin sizlere sunulabilmesi, sizlere karşı olan yükümlülüklerimizin yerine getirilmesi, kayıt ve belgelerin düzenlenebilmesi, yerel ve uluslararası yasal mevzuatın öngördüğü bilgi saklama, raporlama, bilgilendirme, vergi ve sair yükümlülüklere uymak,
</br>- Hizmet ve ürünlerin kalitesinin artırılmasına yönelik yapılacak olan satış ve pazarlama faaliyetleri için yapılacak size özel reklam, kampanya, avantajlar ve diğer faydaları sunmak,
</br>- Altyapı ve ticari faaliyetleri yönetmek ve denetim, finans ve muhasebe, faturalama ve tahsilatlar, bilgi işlem sistemleri, veriler ve internet sitesi barındırma, iş devamlılığı ve kayıtlar, belge ve baskı yönetimi ile bağlantılı olanlar da dahil olmak üzere, iç politika ve prosedürlere uymak,
</br>- Bilgi işlem gereksinimleri, sistemsel yapısı, alınan bilgi işlem destek hizmetlerinin gerekliliği, bu hizmet ve ürünlere ilişkin olarak sizlere gerekli bilgilerin aktarılması amacıyla iletişim kurmak,
</br>- Satış ve pazarlama faaliyetleri için yapılacak trafik ölçümleme, istatistiki analizler, Segmentasyon/profilleme ve CRM çalışmalarını yürütmek,
</br>- Müşteri memnuniyetinin ölçümlenmesi ve artırılması, şikâyet yönetimi, yeni hizmet ve ürünler ile ilgili görüş ve önerilerinizi almak, sorun-hata bildirimlerinizi almak, ürün ve hizmetlere, şikayet ve taleplerinize yönelik tarafınıza bilgi vermek,
</br>- Siparişlerinizi almak, ödeme işlemlerinizi gerçekleştirmek, 3. kişiler ile lojistik iş birliği sağlayıp ürün gönderimini sağlamak, ilginizi çekebilecek ürün ve hizmetleri önermek, online davranışsal reklamcılık ve pazarlama, müşteri portföy yönetimi, hizmet kalitesinin ölçülmesi ve geliştirilmesi, 
</br>- Karşılaştırmalı ürün ve/veya hizmet teklifi, modelleme, mevcut veya yeni ürün çalışmaları ve/veya geliştirmeleri, kişisel verilerinizi ŞİRKET’e açıklamanıza konu olan ŞİRKET ana sözleşmesinde yazılı olan işleri düzenleyen kanun ve ilgili mevzuat kapsamında sizlere sunulacak her türlü ürün ve hizmetlerde kullanılmak,
</br>- Resmî kurumlarca öngörülen bilgi saklama, raporlama, bilgilendirme yükümlülüklerine uymak, sözleşmelerin gerekliliklerini yerine getirmek ve bu hizmetlerden faydalanılmasına ilişkin olarak ŞİRKET’in tabi olduğu yasal yükümlülükleri ifa etmek,
</br>- ŞİRKET’in ticari ve iş stratejilerinin belirlenmesi ve uygulanması amacı doğrultusunda; ŞİRKET tarafından yürütülen finans operasyonları, iletişim, pazar araştırması ve sosyal sorumluluk aktiviteleri, satın alma operasyonları (talep, teklif, değerlendirme, sipariş, bütçelendirme, sözleşme), şirket içi sistem ve uygulama yönetimi operasyonları, hukuki operasyonları yönetmek
</br>- Kara para aklamayı önleme ve terör karşıtı yasalar da dahil olmak üzere, yürürlükteki yasalara ve düzenleyici yükümlülüklere (ikamet ülkenizin dışındakiler de dahil) uymak, hukuki sürece uymak ve resmi makamlardan ve devlet makamlarından (ikamet ülkenizin dışındakiler de dahil) gelen talepleri incelemek, değerlendirmek ve yanıtlamak, amaçlarıyla 6698 sayılı Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları dahilinde işlenecektir.
<br><br>
c) İşlenen Kişisel Verilerin Aktarımı</br>
Toplanan kişisel verileriniz; yukarıda belirtilen amaçların gerçekleştirilmesi ile sınırlı olmak üzere;
KVKK’nın 8. ve 9. maddeleri uyarınca kişisel verileriniz yukarıda sayılan amaçlar dahilinde, YD YAZILIM VE TEKNOLOJİ A.Ş.’nin faaliyetlerinin sürdürülebilmesi için Kişisel Veri işleme şartları ve amaçları çerçevesinde gerekli görülen üçüncü kişilere (program ortağı, işbirliği yapılan kurum, tedarikçi, şirket yöneticileri/çalışanları, hukuken yetkili kamu kurumu ve kuruluşları, hukuken yetkili özel hukuk kişileri ya da yurt dışı ülkeler) aktarılabilecektir. 
ç) Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi</br>
Kişisel verileriniz ŞİRKET Genel Müdürlük, anlaşmalı internet siteleri üzerinden yapılmış olan başvurular, destek hizmeti verdiğimiz / aldığımız sair kurumlar ile her türlü mevzuat veya sözleşme dahilinde işlem yapılan gerçek ve / veya tüzel kişiler, internet sitemiz ve mobil uygulamamız, çağrı merkezlerimiz, sosyal medya hesaplarımız gibi mecralardan sözlü, yazılı veya elektronik ortamda veya ilerde kurulacak/oluşabilecek diğer kanallar başta olmak üzere;
ŞİRKET tarafından yasal mevzuat çerçevesinde yukarıda belirtilen amaçlarla, sözleşmenin ifası dahilinde toplanmaktadır.
</br>
</br>
d) Kişisel Veri Sahibinin 6698 sayılı Kanun’un 11. Maddesinde Sayılan Hakları </br>
Bu kapsamda kişisel veri sahipleri;</br>
o\tKişisel veri işlenip işlenmediğini öğrenme,</br>
o\tKişisel verileri işlenmişse buna ilişkin bilgi talep etme,</br>
o\tKişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</br>
o\tYurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</br>
o\tKişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</br>
o\t6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</br>
o\tİşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</br>
o\tKişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde zararın giderilmesini talep etme haklarına sahiptir.</br>
Yukarıda belirtilen haklarınızı kullanma ile ilgili talebinizi, 6698 sayılı Kanunu’nun 13. maddesinin 1. fıkrası ve 30356 sayılı ve 10.03.2018 tarihli Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ gereğince Türkçe ve yazılı olarak veya kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da ŞİRKET’e daha önce bildirilen ve sistemimizde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle iletebilirsiniz. Başvurularda sadece başvuru sahibi kişi hakkında bilgi verilecek olup diğer aile fertleri ve üçüncü kişiler hakkında bilgi alınması mümkün olmayacaktır. ŞİRKET’in cevap vermeden önce kimliğinizi doğrulama hakkı saklıdır. </br>
Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi aşağıda düzenlenen yöntemlerle iletmeniz durumunda ŞİRKET talebin niteliğine göre talebi en kısa sürede ve en geç otuz gün içinde sonuçlandıracaktır.</br>
Yazılı olarak yapmak istediğiniz başvurularınızı, açık, anlaşır bir şekilde ve kimlik ve adres bilgilerinizi tespit edici belgeleri de ekleyerek, yazılı ve ıslak imzalı olarak, gerekli belgeleri ekleyerek veri sorumlusu olarak Şirketimizin ODTÜ Teknokent İkizler Binası No:13-14 06800 Çankaya/ANKARA adresine verebilirsiniz. </br>
E-posta yoluyla yapmak istediğiniz başvurularınızı ino@ydyazilim.com e-posta adresine yapabilirsiniz. KEP yoluyla yapmak istediğiniz başvurularınızı ydyazilim@hs03.kep.tr KEP adresimize yapabilirsiniz.</br>
Talebinizin niteliğine göre kimlik tespitine olanak sağlayacak bilgi ve belgelerin eksiksiz ve doğru olarak tarafımıza sağlanması gerekmektedir. İstenilen bilgi ve belgelerin gereği gibi sağlanmaması durumunda, ŞİRKET tarafından talebinize istinaden yapılacak araştırmaların tam ve nitelikli şekilde yürütülmesinde aksaklıklar yaşanabilecektir. Bu durumda, ŞİRKET kanuni haklarını saklı tuttuğunu beyan eder. Bu nedenle, başvurunuzun talebinizin niteliğine göre eksiksiz ve istenilen bilgileri ve belgeleri içerecek şekilde gönderilmesi gerekmektedir.</br>
`,
    KnowLedgeText: `
<div id="onbKonu">
1.KONU <br><br>

İşbu  Ön  Bilgi  Formu’nun  konusu,  SATICI' nın,  ALICI'  ya satışınıyaptığı,aşağıda  nitelikleri  ve satışfiyatıbelirtilen Dijital Hizmet satışı ve kullanılması ile ilgili olarak Türk Ticaret Kanunu, Borçlar Kanunu ve 6502 sayılı   Tüketicilerin  Korunması Hakkındaki   Kanunu   ve   Mesafeli  Sözleşmeler Yönetmeliği   hükümleri gereğincetarafların hak ve yükümlülüklerini kapsamaktadır. 
 <br><br>
 ALICI,  SATICI’ nın  unvan, açık  adres,  telefon  ve diğererişim  bilgileri, satışa  konu  Dijital  Hizmet’  in  temel nitelikleri, satışfiyatı, ödeme yöntemleri satışa konu Dijital Hizmet ile ilgili tüm ön bilgiler ve ilgili şikayetve itirazlarıniletilebileceği  resmi  makamlar hakkındaaçık,anlaşılır  ve  internet ortamına  uygun şekildeSATICI tarafındanbilgilendirildiğini; bu ön bilgileri elektronik ortamda teyit ettiğini,Alıcı, Telepati Mesafeli SatışSözleşmesi’nin 5. maddesi uyarınca, bir Ücretli Abonelik için kaydolması halinde, aşağıdakikoşullarauygun  şekilde,  ilgili  hizmet  için  üye  olduğu  günden  itibaren  on  dört  (14)  gün  içinde  (Cayma  Dönemi) herhangi bir nedenle ya da nedensiz olarak fikrini değiştirebileceğini ve ödenen tüm paraların tamamen geri ödemesini alabileceğini, bir Deneme Sürümü için üye olursa Deneme Sürümü aldığı Ücretli Abonelik için Cayma Döneminin, Deneme Sürümünü başlatmasından on dört (14) gün sonra sona ereceğini teyit, kabul   ve   beyan   eder.  Alıcı,   Deneme   Sürümü   sona   ermeden   önce   Ücretli  Aboneliğinin   iptal edilemeyeceğini, böyle bir durumda alıcının cayma hakkınıkaybedeceğini ve telepati.live Ücretli Aboneliğiiptal  edene  kadar  alıcıya  her  ay  kararlaştırılan tutarın  otomatik  olarak  yansıtılacağını, alıcı tarafındanDeneme Sürümü olmayan bir Ücretli Abonelik satınalınması halinde telepati.live alıcı iptal edene kadar her  ay  otomatik  olarak  kendisine  ücret  yansıtması  için  Satıcı Firmayı yetkilendirmiş olacağını,  Cayma Döneminin, satın alma tarihinden sonraki on dört (14) gün için geçerli olduğunu ancak bu dönem içinde telepati.live Hizmetinin kullanılması halinde cayma hakkınınkaybedildiğini teyit, kabul ve beyan eder.
 <br><br>

</div>
 
<div id="onbTaraflar">
<strong>2. TARAFLAR</strong>  <br><br>
SATICI:  <br><br>

Ünvanı : YD Yazılım ve Teknoloji A.Ş. </br>

MERSİS No: 0946044430200001 <br/>

Adres : ODTÜ Teknokent, İkizler Blok, Zemin Kat 35/14 06531 Çankaya/ANKARA  </br>

E-mail :  destek@telepati.live  </br>

Web adresi : www.telepati.live

 <br><br>

 ALICI: <br><br> 

Adres : </br>

Telefon :  </br>

Eposta : </br>

 <br><br>
</div>

<div id="onbSozlesmeKonusuHizmet">
<strong>3. SÖZLEŞME KONUSU HİZMET</strong> <br><br>

3.1.  Bu  Ön  Bilgilendirme  Formu nun  konusu,  aşağıda  nitelikleri  ve  satış  bedeli  belirtilen https://telepati.live    internet    sitesinden    elektronik    ortamda  müşterinin sipariş verdiği,telepati.live uygulamasının elektronik ortamda satınalınmasını içerir. Söz konusu hizmetin satışve özelliklerine dair 6052 sayılı Tüketicinin KorunmasıHakkındaki Kanun ve Mesafeli SözleşmeleriUygulama   Esas   ve   Usulleri  Hakkında   Yönetmelik   hükümleri  gereğince tarafların   hak   ve yükümlülükleri belirlenmiş ve hüküm altınaalınmıştır. <br><br>

3.2. Hizmet paketine ilişkin bilgiler aşağıda yer almaktadır. Paket fiyatına tüm vergiler dahildir.<br><br>

 <table style="border:1px solid #f44336">
            <tr style="font-weight: 700; ">
              <td style="width: 33%;border-bottom:1px solid #f44336; border-right:1px solid #f44336">Paket Adı</td>
              <td style="width: 33%;border-bottom:1px solid #f44336; border-right:1px solid #f44336">Paket Adedi</td>
              <td style="width: 33%;border-bottom:1px solid #f44336">Paket Toplam Tutar</td>
            </tr>
            <tr>
              <td style="border-right:1px solid #f44336">...</td>
              <td style="border-right:1px solid #f44336">...</td>
              <td>..</td>
            </tr>
          </table>
          
          <br><br>

<strong>Toplantı Özellikleri</strong> <br><br>
3.3. Telepati  telefon,  tablet  ve  bilgisayarların tarayıcıları aracılığıyla  telekonferans  görüşmesiyapmayı sağlar. Ayrıca  telekonferans  görüşmeleri esnasında  sunum  yapma,  video  oynatma, sohbet etme ve toplantıkaydı alma gibi özellikler de sağlamaktadır.<br><br>
3.4. Tüm kullanıcılar anında toplantı oluşturma özelliğinden yararlanabilir. İleriye dönük toplantı planlayabilmek  için https://telepati.live/pricing ‘de  yer  alan  paketlerden  birinin  satın alınmışolması gerekmektedir.<br><br>
3.5. ALICI,  toplantı   süresini   kendi   belirlemektedir.   Minimum  toplantı   süresi   1   dakika   ile sınırlandırılırmıştır.<br><br>
3.6. Alıcı,uygulamanın  telekonferans görüşmeleri esnasında,  moderatör katılımcılartarafındantoplantıkaydı  (görüntü,  ses, paylaşılan  sunum  ve  notlar) alınabileceğini  teyit,  beyan  ve  kabul eder.<br><br>
<strong>Genel Gereksinimler ve Kullanım Süresi</strong> <br><br>
3.8.     Telepati   uygulaması  yalnızca     online   kullanılabilmektedir.  Ayrıca,     telekonferans görüşmeleriningerçekleştirilebilmesi için sahip olunan elektronik cihazın mikrofon, hoparlör ve kamera özelliklerinden en azından birine sahip olması gereklidir.<br><br>
3.9. İşbu Ön Bilgilendirme Formunda, 9. maddede ve Üyelik Sözleşmesi ve KullanımŞartlarındaaçıkça belirtilen donanıma sahip olması beklenir.<br><br>
3.10.Satınalınan  telepati.live  Hizmetini kullanması  için alıcı tarafından sonlandırılıncaya  kadar yürürlükte  kalacak  erişim  ve  kullanım  izni  verilmiş olacaktır.  Bu  Erişim, alıcı tarafından  ya  da https://telepati.live tarafındansonlandırılana kadar yürürlükte kalacaktır.<br><br>
 
</div>

<div id="onbOdemeYontemleri">
<strong>4. ÖDEME YÖNTEMLERİ</strong> <br><br>

 

Ödeme, aşağıda belirtilen yöntemlerden biri ile yapılabilecektir:</br> </br>
Kredi Kartı ile Ödeme: Telepati uygulamasından satın alınmak istenen Paket’ i ödeme aşamasında kredi  kartı  bilgilerinin  girilmesi  suretiyle  yapılan  ödeme  yöntemidir.  Bu  ödeme  yönetiminin seçilmesi halinde, Hizmet Bedeli, güvenli ödeme sağlayıcıİyzicoaracılığıyla tahsil edilmektedir.
 <br><br>
 Ödeme  sırasında   ALICI  tarafından yapılabilecek   hatalar   nedeni   ile   ödemede  çıkabilecekaksaklıklardan SATICI sorumlu değildir.
<br><br>
İnternet üzerinden kredi kartı ile yapılan ödemelerin bedelleri üyenin kredi kartından otomatik olarak  çekilir.  Ödeme  işlemi onaylandıktan  sonra  Telepati  lisansı  aktif  edilir.  SATICI,  Telepati uygulamasında indirim veya artırım ücret uygulamasını döneme göre değiştirmehakkına sahiptir.
<br><br>
</div>

 

<div id="onbCaymaHakki">
<strong>5. CAYMA HAKKI</strong> <br><br>
5.1 Faturalandırma Hizmetin cinsi, türü ve tüm vergiler dâhil satış bedeli, gerek bu sözleşme degerekse  https://telepati.live adlı  web  sitesindeki  hizmet tanıtımsayfasında  yer  alan  bilgilerde belirtildiği gibidir.
<br><br>
5.2  Ödeme Planı  Kredi kartı  ödeme  tarihi  banka  ile müşteri arasındaki sözleşme  hükümlerince belirlenir.  Müşteri, ayrıca bankanın gönderdiği  hesap  özetinden  ödemelerini  takip  edebilir. telepati.live aylık ön ödemeli kullanım ücreti yada dönemlik ücreti zaman zaman değiştirebilir ve fiyat değişikliğiyapılması durumunda bu durum müşteriye iletecektir.
<br><br>
Fiyat  değişiklikleri olması  durumunda  fiyat  değişikliği  tarihini  takip  eden  bir  sonraki  abonelik döneminin başından itibaren yürürlüğe girer. Kullanım ücreti değişikliğiyürürlüğe girdikten sonra telepati.live Hizmetini kullanmaya devam ederek müşteri yeni fiyatı kabul etmiş olur.Müşteri  bir  fiyat değişikliğini  kabul  etmiyorsa  fiyat değişikliğiyürürlüğe  girmeden  önce  Ücretli Abonelikten çıkarakdeğişikliği reddetme hakkına sahiptir.
<br><br>
Müşteri  bir  Ücretli  Abonelik  için  kaydolması  halinde,  aşağıdaki koşullara  uygun  şekilde,  ilgili hizmet  için  üye olduğu  günden  itibaren  on  dört  (14)  gün  içinde  (Cayma  Dönemi)  herhangi  bir nedenle  ya  da  nedensiz  olarak  fikrini  değiştirebilir  ve  ödenen  tüm  paraların  tamamen  geri ödemesini alabilir.
<br><br>
Müşteri bir Deneme Sürümü için üye olursa Deneme Sürümü aldığı Ücretli Abonelik için Cayma Döneminin,  Deneme  Sürümünü  başlatmasından  on  dört  (14)  gün  sonra  sona  ereceğini  kabul etmiş olur. Deneme Sürümü sona ermeden önce Ücretli Aboneliği iptal edilemez.Böyle bir durumda müşteri cayma hakkını kaybeder ve telepati.live Ücretli Aboneliği iptal edene kadar  müşteriye  her  ay  kararlaştırılan tutarı  otomatik  olarak  yansıtılır. Müşteri tarafındanDeneme  Sürümü  olmayan  bir  Ücretli  Abonelik satınalınması  halinde  telepati.live müşteri  iptal edene kadar her ay otomatik olarak kendisine ücret yansıtması için SatıcıFirmayıyetkilendirmişolur.
<br><br>
Müşteri; Cayma Döneminin, satın alma tarihinden sonraki on dört (14) gün için geçerli olduğunuancak bu dönem içinde telepati.live Hizmetini kullanılması halinde cayma hakkınınkaybedildiğinikabul edilmiş olur.
<br><br>
<strong>Yenileme; İptal</strong> <br><br>
5.3.1 Ücretli Abonelik bir Ön Ödemeli Dönem için satınalınmış olmadıkça, ücretli Aboneliği satın alınması halinde o an geçerli olan abonelik dönemi sona ermeden ücretli Abonelik iptal etmediğimüddetçe geçerli abonelik döneminin sonunda otomatik olarak yenilenir.
<br><br>
5.3.2 İptal, o anki abonelik döneminin son gününden sonraki gün yürürlüğe girer.
<br><br>
<strong>5.4. Ücretli Abonelik</strong>
<br><br>
5.4.1  Cayma  Dönemi  sona  erdikten  sonra  Müşteri  abonelik  dönemi  sona  ermeden  önce ödemesini  ya  da  Ücretli  Aboneliğini  iptal  eder  ve/veya  herhangi  bir  Sözleşmeyi  fesheder  ise halihazırdaödenmiş olan abonelik ücretini geri iade edilmeyeceğini kabul eder.
<br><br>
5.4.2  Müşteri tarafından  Cayma  Dönemi  sona  ermeden  önce  yapılan  ödemenin  geri  talep edilmesi  halinde MüşteriDesteği  ile iletişime  geçmelidir.  Herhangi  bir  geri  ödeme Satıcı  Firma tarafındanişlemealınması  halinde tutarların  geri  ödemesini müşterinin  ödeme  için kullanmışolduğu yöntem kullanarak yapılacaktır.
<br><br>
<strong>6. TELAPATİ UYGULAMASININ KULLANILMASI VE SATICININ BORCUNU İFASI</strong><br><br>
6.1.  SATICI,  İşbu  Ön  Bilgilendirme  Formu’  nda  ALICI’  yı  hizmet  ve  hizmet  bedeli  hakkında bilgilendirir. 
<br><br>
6.2.  SATICI, satın alınmanın gerçekleşmesinin ardından,satın alınan lisansı  ALICI’ nın hesabına tanımlar.
<br><br>
6.3. SATICI, ALICI’ nın teknik problemler yaşaması halinde teknik destek sağlar.
<br><br>
6.4. SATICI,ALICI’ nınhizmetten yararlanabilmesi için gerekli tüm yasal yükümlülüklerini yerine getirir. ALICI’ yı bunlarla ilgili bilgilendirici hukuki metinleri paylaşır; gerekli açıkrıza/onaylarıalır.
<br><br>
</div>


<div id="onbHizmetUygulamaKapsamindakiFikriveSinaiHaklar">
<strong>7. HİZMET/UYGULAMAKAPSAMINDAKİFİKRİ VE SINAİ HAKLAR</strong><br><br>
ALICI,  uygulamada  yer   alan   görsel,  grafik,  ses  vb.   her  türlü  medya  ve  içeriklerin  SATICI mülkiyetinde  ya  da  mali  ve/veya  kullanım haklarının  SATICI’  ya  ait  olduğunu;  Hizmet’  i  satınalmakla ya da Uygulamayı kullanmakla Mesafeli SatışSözleşmesi, Üyelik Sözleşmesi ve KullanımŞartları’  nda  belirlenen şekilde  ve  Hizmet kapsamında  belirlenen  süre  ile  mevzuat  ve sözleşmesınırları içinde, münhasıran kullanma hakkını haiz olduğunu beyan ve kabul eder.
<br><br>
</div>

<div id="onbKisiselVerilerinKorunmasi">
<strong>8. KİŞİSELVERİLERİN KORUNMASI</strong><br><br>
8.1.  ALICI,  satın  alma  aşamalarından  herhangi  birinde  SATICI  ile  paylaşılan kişisel  verilerinin; sözleşmenin kurulmasını sağlamak  ve  kanundan  doğan  kendi  yükümlülüğünü  ifa  etmek  için işleyeceğini ve muhafaza edeceğini bilir.
<br><br>
8.2. ALICI, Kişisel Verilerin Korunması Kanunu kapsamında hazırlanan Aydınlatma Metni’ ne üyelik aşamasında,ayrıcahttps://telepati.live/ internet sitesinde KVKK Aydınlatma Metni bölümünden erişebilir.
<br><br>
</div>

<div id="onbTelepatiUygulamasininCalismasiHakkinda">
<strong>9.  TELEPATİ UYGULAMASININ ÇALIŞMASI HAKKINDA</strong><br><br>
9.1.  Dijital  Hizmet,  internet  erişimi  gerektirir.  Söz  konusu  internet  bağlantısının sağlanması,kalitesi ve güvenliği ALICI sorumluluğundadır.
<br><br>
9.2. Uygulamaya girişte mail adresi bilgileri talep edilmekte olup; Alıcı’nın mailine yönlendirilen doğrulama linki ile kayıtişlemigerçekleştirilir.
<br><br>
9.4.  Telepati,  tüm  telefon,  bilgisayar  ve  tabletlerin  tarayıcıları  üzerinden  kullanılabilir.  Bu bakımdan, ALICI gerekli donanıma sahip cihaza sahip olmaktan sorumludur.
<br><br>
<br><br>

</div>


<div id="onbMucbirSebep">
<strong>10. MÜCBİR SEBEP</strong><br><br>
10.1Hukuken  'mücbir  sebep'  sayılan  ve  kabul  edilen  tüm  hallerde,  SATICI’  nın işbu  Ön Bilgilendirme  Formu  ve  Mesafeli  Satış Sözleşmesi  ile  belirlenen  yükümlülüklerinden  herhangi birini  geç  veya  eksik  ifa  etmesi  veya  ifa  etmemesi  halinde  sorumluluğu  geçici  olarak  askıyaalınacaktır. ALICI/Üye Öğrenci/Veli bu veya bu gibi durumlarda, SATICI’ nın ifada gecikmesi, eksik ifası, ifa etmemesi veya temerrüt olarak adlandırılmayacağını veya bu durumlar için SATICI’ dan herhangi bir isim altında tazminat talep edilemeyeceğini bilir ve kabul eder.
<br><br>
10.2."Mücbir  sebep"  terimi; doğal  afet, salgınhastalık,  isyan, savaş,  grev, iletişim sorunları,altyapı ve internet arızaları, elektrik kesintisi ve kötü hava koşulları, mahkemelerce web sitesine erişimin engellenmesine yönelik verilen ihtiyati tedbir kararları da dahil ve fakat bunlarla sınırlıolmamak  kaydıyla,    SATICI’  nın    kusuruyla    neden  olmadığı,    kontrolü    haricinde  gelişenkaçınılamayacak olaylar olarak yorumlanır.
<br><br>
</div>

<div id="onbUyusmazlikHalindeBasvuruMercileri">
<strong>11. UYUŞMAZLIK HALİNDE BAŞVURU MERCİİLERİ</strong><br><br>
11.1.    ALICI,  soru,  talep  ve  şikayetleri  için   destek@telepati.live  e-posta  adresinden  irtibata geçebileceğini bilir.
<br><br>
11.2.  Ayrıca, ALICI şikâyet ve itirazlar konusunda, her takvim yılıbaşından itibaren geçerli olmak üzere  Ticaret  Bakanlığı tarafından  ilan  edilen  parasal  sınıra  kadar  olan  başvurularını  Tüketici Hakem Heyeti'ne; söz konusu parasal sınırın üzerindeki başvurularını ise Tüketici Mahkemesi’ne yapabilir.
<br><br>
11.3.    ALICI,  Hizmet’  in  temel  niteliklerine, satışfiyatına,  ödeme şekline,ifasına ilişkin  bilgiler dahil fakat bunlarla sınırlı olmamak üzere Hizmet ile ilgili her türlü bilgiyi okuduğunu,anladığınıve onayladığını kabul ve beyan eder.
<br><br>
11.4.    ALICI’ nınişbu  Ön  Bilgilendirme  Formu’  nun  bir örneğini yazıcıdan alabileceği  gibi  dijital olarak da saklaması mümkündür. ALICI tarafından onaylanan işbu Ön Bilgilendirme Formu’ nun bir örneği, SATICI tarafındansaklanır.
<br><br>
</div>
<div id="onbIstisna">
<strong>12.   İSTİSNA</strong><br><br>
İşbu ön bilgilendirme formunda yer alan ve 6502 sayılı Tüketicinin Korunması Hakkında Kanundan doğarak tüketicilere hukuki koruma sağlayan madde hükümleri sadece ALICI’ nın Tüketici olduğuhallerde  geçerli  olarak  hüküm  ifade  edecek  olup; alıcının  6502 sayılı  kanunda  yer  alan  Tüketici tanımınauymadığı hallerde ilgili maddeler taraflar arasında hüküm ifade etmeyecektir.
<br><br>
<br><br>
<strong>
ALICI;  6502  S.K.’un  M.  48,  f.2  ve  Mes.  Söz.  Yön.  5.,  6.  ve  7.  maddeleri gereğince  Ön  Bilgileri okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini kabul, taahhüt ve beyan eder.
</strong>
<br><br>
</div>
`


};
