import React, {useEffect} from 'react';
import {Box, Link, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import {BrowserRouter} from 'react-router-dom';
import BgBottomFigure from '../../../common/BgBottomFigure';
import {useTranslation} from 'react-i18next';

function PrivacyPolicy() {
    const {t} = useTranslation();
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, []);

    return (
        <div>
            <Grid container p={3} paddingTop={{md: '83px', xs: '20px'}} style={{marginBottom: '50px'}}>
                <Grid item md={2}/>
                <Grid item md={8}>
                    <BrowserRouter>
                        <Typography
                            variant="h1"
                            fontSize={{md: '3rem', xs: '2rem'}}
                            fontWeight={700}
                            color={'#000'}
                            mb={2}
                        >
                            {t('privacyPolicy')}
                        </Typography>
                        <Box borderBottom={'3px solid #44327E'}/>
                        <Navigator/>
                        <PolitikaHakkinda/>
                        <HaklarVeKontroller/>
                        <HakkinizdaTopladigimizKisiselVeriler/>
                        <KullanmaAmacimiz/>
                        <VerilerinPaylasilmasi/>
                        <VerilerinSaklanmasiSilinmesi/>
                        <VerilerinGuvendeTutulmasi/>
                        <BizimleIletisimeGecme/>
                    </BrowserRouter>
                </Grid>
                <Grid item md={2}/>
            </Grid>

            <BgBottomFigure/>
        </div>
    );
}

function Navigator() {
    const {t} = useTranslation();

    function goToSection(e, sectionName) {
        let elem = document.getElementById(sectionName);
        e.preventDefault();
        elem && elem.scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    return (
        <Box
            paddingTop={{md: '52px', xs: '20px'}}
            paddingBottom={{md: '20px', xs: '20px'}}
            sx={{'& a': {color: '#9A78FB', textDecoration: 'none'}}}>
            <Link
                fontSize={{md: '25px', xs: '16px'}}
                style={{cursor: 'pointer'}}
                onClick={e => goToSection(e, 'PolitikaHakkinda')}
            >
                {t('privacyPolicyItem1Title')}
            </Link>
            <br/>
            <Link
                fontSize={{md: '25px', xs: '16px'}}
                style={{cursor: 'pointer'}}
                onClick={e => goToSection(e, 'HaklarVeKontroller')}
            >
                {t('privacyPolicyItem2Title')}
            </Link>
            <br/>
            <Link
                fontSize={{md: '25px', xs: '16px'}}
                style={{cursor: 'pointer'}}
                onClick={e => goToSection(e, 'HakkinizdaTopladigimizKisiselVeriler')}
            >
                {t('privacyPolicyItem3Title')}
            </Link>
            <br/>
            <Link
                fontSize={{md: '25px', xs: '16px'}}
                style={{cursor: 'pointer'}}
                onClick={e => goToSection(e, 'KullanmaAmacimiz')}
            >
                {t('privacyPolicyItem4Title')}
            </Link>
            <br/>
            <Link
                fontSize={{md: '25px', xs: '16px'}}
                style={{cursor: 'pointer'}}
                onClick={e => goToSection(e, 'VerilerinPaylasilmasi')}
            >
                {t('privacyPolicyItem5Title')}
            </Link>
            <br/>
            <Link
                fontSize={{md: '25px', xs: '16px'}}
                style={{cursor: 'pointer'}}
                onClick={e => goToSection(e, 'VerilerinSaklanmasiSilinmesi')}
            >
                {t('privacyPolicyItem6Title')}
            </Link>
            <br/>
            <Link
                fontSize={{md: '25px', xs: '16px'}}
                style={{cursor: 'pointer'}}
                onClick={e => goToSection(e, 'VerilerinGuvendeTutulmasi')}
            >
                {t('privacyPolicyItem7Title')}
            </Link>
            <br/>
            <Link
                fontSize={{md: '25px', xs: '16px'}}
                style={{cursor: 'pointer'}}
                onClick={e => goToSection(e, 'BizimleIletisimeGecme')}
            >
                {t('privacyPolicyItem8Title')}
            </Link>
            <br/>
        </Box>
    );
}

function PolitikaHakkinda() {
    const {t} = useTranslation();
    return (
        <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
            <section id="PolitikaHakkinda" style={{height: '900', color: '#000'}}>
                <Typography
                    variant="h1"
                    fontSize={{md: '30px', xs: '20px'}}
                    fontWeight={700}
                    marginBottom={{md: '20px', xs: '10px'}}
                    color={'#000'}>{t('privacyPolicyItem1Title')}</Typography>
                <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'}
                            dangerouslySetInnerHTML={{__html: t('privacyPolicyItem1Text')}}>
                </Typography>
            </section>
        </Box>
    );
}

function HaklarVeKontroller() {
    const {t} = useTranslation();
    return (
        <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
            <section id="HaklarVeKontroller" style={{height: '900', color: '#000'}}>
                <Typography
                    variant="h1"
                    fontSize={{md: '30px', xs: '20px'}}
                    fontWeight={700}
                    marginBottom={{md: '20px', xs: '10px'}}
                    color={'#000'}>{t('privacyPolicyItem2Title')}</Typography>
                <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'}
                            dangerouslySetInnerHTML={{__html: t('privacyPolicyItem2Text')}}>
                </Typography>
            </section>
        </Box>
    );
}

function HakkinizdaTopladigimizKisiselVeriler() {
    const {t} = useTranslation();
    return (
        <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
            <section id="HakkinizdaTopladigimizKisiselVeriler" style={{height: '900', color: '#000'}}>
                <Typography
                    variant="h1"
                    fontSize={{md: '30px', xs: '20px'}}
                    fontWeight={700}
                    marginBottom={{md: '20px', xs: '10px'}}
                    color={'#000'}>{t('privacyPolicyItem3Title')}</Typography>
                <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'}
                            dangerouslySetInnerHTML={{__html: t('privacyPolicyItem3Text')}}>
                </Typography>
            </section>
        </Box>
    );
}

function KullanmaAmacimiz() {
    const {t} = useTranslation();
    return (
        <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
            <section id="KullanmaAmacimiz" style={{height: '900', color: '#000'}}>
                <Typography
                    variant="h1"
                    fontSize={{md: '30px', xs: '20px'}}
                    fontWeight={700}
                    marginBottom={{md: '20px', xs: '10px'}}
                    color={'#000'}>{t('privacyPolicyItem4Title')}</Typography>
                <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'}
                            dangerouslySetInnerHTML={{__html: t('privacyPolicyItem4Text')}}>
                </Typography>
            </section>
        </Box>
    );
}

function VerilerinPaylasilmasi() {
    const {t} = useTranslation();
    return (
        <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
            <section id="VerilerinPaylasilmasi" style={{height: '900', color: '#000'}}>
                <Typography
                    variant="h1"
                    fontSize={{md: '30px', xs: '20px'}}
                    fontWeight={700}
                    marginBottom={{md: '20px', xs: '10px'}}
                    color={'#000'}>{t('privacyPolicyItem5Title')}</Typography>
                <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'}
                            dangerouslySetInnerHTML={{__html: t('privacyPolicyItem5Text')}}>
                </Typography>
            </section>
        </Box>
    );
}

function VerilerinSaklanmasiSilinmesi() {
    const {t} = useTranslation();
    return (
        <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
            <section id="VerilerinSaklanmasiSilinmesi" style={{height: '900', color: '#000'}}>
                <Typography
                    variant="h1"
                    fontSize={{md: '30px', xs: '20px'}}
                    fontWeight={700}
                    marginBottom={{md: '20px', xs: '10px'}}
                    color={'#000'}>{t('privacyPolicyItem6Title')}</Typography>
                <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'}
                            dangerouslySetInnerHTML={{__html: t('privacyPolicyItem6Text')}}>
                </Typography>
            </section>
        </Box>
    );
}

function VerilerinGuvendeTutulmasi() {
    const {t} = useTranslation();
    return (
        <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
            <section id="VerilerinGuvendeTutulmasi" style={{height: '900', color: '#000'}}>
                <Typography
                    variant="h1"
                    fontSize={{md: '30px', xs: '20px'}}
                    fontWeight={700}
                    marginBottom={{md: '20px', xs: '10px'}}
                    color={'#000'}>{t('privacyPolicyItem7Title')}</Typography>
                <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'}
                            dangerouslySetInnerHTML={{__html: t('privacyPolicyItem7Text')}}>
                </Typography>
            </section>
        </Box>
    );
}

function BizimleIletisimeGecme() {
    const {t} = useTranslation();
    return (
        <Box paddingTop={{md: '52px', xs: '20px'}} paddingBottom={{md: '30px', xs: '20px'}}>
            <section id="BizimleIletisimeGecme" style={{height: '900', color: '#000'}}>
                <Typography
                    variant="h1"
                    fontSize={{md: '30px', xs: '20px'}}
                    fontWeight={700}
                    marginBottom={{md: '20px', xs: '10px'}}
                    color={'#000'}>{t('privacyPolicyItem8Title')}</Typography>
                <Typography fontSize={{md: '20px', xs: '16px'}} lineHeight={'27px'}
                            dangerouslySetInnerHTML={{__html: t('privacyPolicyItem8Text')}}>
                </Typography>
            </section>
        </Box>
    );
}

export default PrivacyPolicy;
