import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';

const BodyMain = ({children}) => {
    const {i18n} = useTranslation();
    useEffect(() => {
        setDefaultLang();
    }, []);

    function setDefaultLang() {
        if (localStorage.getItem('lngChnged') !== 'true') {
            localStorage.setItem('i18nextLng', 'tr');
        }
    }

    return (
        <main>
            <Box>{children}</Box>
        </main>
    );
};

BodyMain.propTypes = {
    children: PropTypes.node
};

export default BodyMain;
