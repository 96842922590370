import React from 'react';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import {Form} from './components';

const ForgotPassword = () => {
    return (
        <Box
            position={'relative'}
            minHeight={'calc(50vh)'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            height={'100%'}
            sx={{borderBottom: 'none', borderTop: 'none'}}
        >
            <Container maxWidth={500}>
                <Form/>
            </Container>
        </Box>
    );
};

export default ForgotPassword;
