import {persistReducer,} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import {getPersistConfig} from 'redux-deep-persist';
import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import monitorReducersEnhancer from './enhancers/monitorReducerEnhancer';
import loggerMiddleware from './middleware/logger';

const persistConfig = getPersistConfig({
    key: 'root',
    storage,
    blacklist: [
        'router', 'main.packages'
    ],
    rootReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(preloadedState) {
    const middlewares = [loggerMiddleware, thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
    const composedEnhancers = compose(...enhancers);

    const store = createStore(persistedReducer, preloadedState, composedEnhancers);

    return store;
}
