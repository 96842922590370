import {Box, Button, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import PricingTable from './components/PricingTable';
import {useTranslation} from 'react-i18next';
import Container from 'common/Container';

function Pricing() {
    const {t, i18n} = useTranslation();
    // eslint-disable-next-line no-unused-vars
    const [isMobile, setIsMobile] = React.useState(false);
    const [screenSize, getDimension] = React.useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    const setDimension = () => {
        if (screenSize.dynamicWidth > 600) {
            setIsMobile(false);
        } else {
            setIsMobile(true);
        }

        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        });
    };

    useEffect(() => {
        setDimension();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        });
    }, [screenSize]);

    const [licenseType, setLicenseType] = useState('monthly');

    const priceTypeChange = (licenseType) => {
        setLicenseType(licenseType);
    };

    return (
        <Box component={'div'} sx={{
            background: '#fff',
            flex: 1,
            display: 'flex',
            marginBottom: '100px',
            justifyContent: 'center',
            position: 'relative'
        }}>
            <Container>
                <Box sx={{margin: '0 10px 0 10px'}}>
                    <Typography variant="h6" sx={{color: '#3E3E3E', fontWeight: 700}}>
                        {t('packageTitle')}
                    </Typography>
                    <Box marginTop={2}>
                        <Grid container>
                            <Grid item md={8} mt={2}>
                                <Typography variant="body1" sx={{color: '#3E3E3E', fontWeight: 500}}>
                                    {t('packageText')}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                                mt={2}
                                textAlign={{md: i18n.language === 'ar' ? 'left' : 'right', xs: 'center'}}>
                                <Button
                                    onClick={() => priceTypeChange('monthly')}
                                    variant="contained"
                                    color={licenseType === 'monthly' ? 'primary' : 'secondary'}
                                    sx={{
                                        width: 101,
                                        height: 42,
                                        marginRight: '10px',
                                        color: licenseType === 'monthly' ? '#fff' : '#f44336'
                                    }}
                                >
                                    {t('packetsMonthly')}
                                </Button>
                                <Button
                                    onClick={() => priceTypeChange('annual')}
                                    variant="contained"
                                    color={licenseType === 'annual' ? 'primary' : 'secondary'}
                                    sx={{
                                        width: 101,
                                        height: 42,
                                        marginRight: i18n.language === 'ar' ? '10px' : 0,
                                        color: licenseType === 'annual' ? '#fff' : '#f44336'
                                    }}
                                >
                                    {t('packetsYearly')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <PricingTable licenseType={licenseType} minimal={false}/>
            </Container>
        </Box>

    );
}

export default Pricing;
