import {Card, CardContent, FormControlLabel, Modal} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, {useEffect, useRef, useState} from 'react';
import 'react-phone-input-2/lib/style.css';
import image from '../../../../assets/images/ssl-secure.png';
import storage from '../../../../utils/storage';
import {useSelector} from 'react-redux';
import store from '../../../../redux/store';
import {setPackages, setSelectedPackage} from '../../../../redux/actions';
import InvoiceForm from '../components/InvoiceForm';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {Form, Formik} from 'formik';
import CreditCards2 from '../../../../svg/illustrations/CreditCards2';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import valid from 'card-validator';
import {PricingServices} from '../../../../services';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {LoadingButton} from '@mui/lab';
import CurrencySymbol from '../../../../common/CurrencySymbol';
import Container from '../../../../common/Container';
import {convertDigitIn} from '../../../../utils/text';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '530px',
    maxHeight: '660px',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '5px',
    boxShadow: 24,
    p: 1,
    paddingBottom: 5,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
        width: '0px'
    }
};

const BuyPackage = () => {
    const iFrameRef = useRef();

    // eslint-disable-next-line no-unused-vars
    const history = useHistory();

    // eslint-disable-next-line no-unused-vars
    const [htmlData, setHtmlData] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const packages = useSelector((state) => state.main.packages);
    const selectedPackage = useSelector((state) => state.main.selectedPackage);
    const [licenseType, setLicenseType] = useState('monthly');
    const [invoiceFormValues, setInvoiceFormValues] = useState();
    const {t, i18n} = useTranslation();

    const validationSchema = yup.object().shape({
        cardHolderName: yup
            .string()
            .trim()
            .required(t('requiredField'))
            .matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u, 'Geçerli bir ad soyad giriniz'),
        cardNumber: yup
            .number(t('validCreditCardNumberNumeric'))
            .required(t('requiredField'))
            .test('test-number', t('validCreditCardNumber'), value => valid.number(value).isValid),
        date: yup
            .string()
            .required(t('requiredField'))
            .test('is-true-date', t('validExpireDate'), function (value) {
                if (value) {
                    let nowYear = parseInt(new Date().getUTCFullYear().toString().substr(2, 2));
                    let nowMonth = parseInt(new Date().getUTCMonth() + 1);
                    let valueMonth = parseInt(value.split('/')[0]);
                    let valueYear = parseInt(value.split('/')[1]);

                    if (valueYear < nowYear) {
                        return false;
                    } else if (valueYear == nowYear && valueMonth < nowMonth) {
                        return false;
                    } else {
                        return true;
                    }
                }
            })
            .matches(/^(0\d|1[0-2])\/\d{2}$/, t('validExpireDate')),
        cvc: yup
            .string()
            .required(t('requiredField'))
            .min(3, t('validCvc'))
            .max(3, t('validCvc'))
            .matches(/^[0-9]{2,3}$/, t('validCvc'))
    });
    const steps = [t('InvoiceInformation'), t('PaymentInformation')];

    useEffect(() => {
        const data = JSON.parse(window.localStorage.getItem('selectedPackage'));
        if (data) {
            setLicenseType(data.licenseType);
            store.dispatch(setSelectedPackage(data));
        }
    }, []);

    const licenseTypeChange = (type) => {
        setLicenseType(type);
        const data = {
            ...selectedPackage, licenseType: type
        };
        const packageList = packages.map((packageItem) => {
            if (packageItem.id === data.id) {
                return data;
            } else {
                return packageItem;
            }
        });
        store.dispatch(setSelectedPackage(data));
        store.dispatch(setPackages(packageList));
        storage.set('selectedPackage', data);
    };

    const onStandartUserCountChange = (item, value) => {
        const data = {
            ...item, userCount: parseInt(value)
        };
        const packageList = packages.map((packageItem) => {
            if (packageItem.id === data.id) {
                return data;
            } else {
                return packageItem;
            }
        });
        store.dispatch(setSelectedPackage(data));
        store.dispatch(setPackages(packageList));
        storage.set('selectedPackage', data);
    };

    const onBusinessUserCountChange = (item, value) => {
        const data = {
            ...item, userCount: parseInt(value)
        };
        const packageList = packages.map((packageItem) => {
            if (packageItem.id === data.id) {
                return data;
            } else {
                return packageItem;
            }
        });
        store.dispatch(setSelectedPackage(data));
        store.dispatch(setPackages(packageList));
    };

    const onPackageChange = (item) => {
        const data = {
            licenseType, ...item
        };
        const packageList = packages.map((packageItem) => {
            if (packageItem.id === data.id) {
                return data;
            } else {
                return packageItem;
            }
        });
        store.dispatch(setSelectedPackage(data));
        store.dispatch(setPackages(packageList));
        storage.set('selectedPackage', data);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onInvoiceFormSubmited = (values) => {

        setInvoiceFormValues(values);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const initialValues = {
        cardHolderName: '', cardNumber: '', date: '', cvc: ''
    };

    const onSubmit = (values) => {
        setIsLoading(true);
        // const sendValues = {...values};
        // const sendInvoiceFormValues = {...invoiceFormValues};
        // const encrypt = new JSEncrypt();
        // let allFormValues = Object.assign(sendValues, sendInvoiceFormValues);
        // encrypt.setPublicKey(process.env.REACT_APP_PASSWORD_KEY);
        // const encryptedCardNumber = encrypt.encrypt(allFormValues.cardNumber);
        // const encryptedExpireMonth = encrypt.encrypt(allFormValues.date.split('/')[0]);
        // const encryptedExpireYear = encrypt.encrypt('20' + allFormValues.date.split('/')[1]);
        // const encryptedCvc = encrypt.encrypt(allFormValues.cvc);
        // allFormValues.licenceCount = selectedPackage.userCount;
        // allFormValues.productId = selectedPackage.id == 'standart' ? 2 : 3;
        // allFormValues.expireMonth = encryptedExpireMonth;
        // allFormValues.expireYear = encryptedExpireYear;
        // allFormValues.cardNumber = encryptedCardNumber;
        // allFormValues.cvc = encryptedCvc;
        // allFormValues.subscriptionType = selectedPackage.licenseType === 'annual' ? 2 : 1;

        let exJson = {
            'cardHolderName': 'YUSUF IŞIK',
            'cardNumber': 'xQmB3fwXi6dVQ/uYQldgvVs79iB9H2RGKWrH8mAwanvjpgIpgZYGvQOTkHUA0eF0lzXRQSHv+UMnXouNupF2scSLs+nK25/5osZBiCkMd891QsUUr9dLYuTtIpBEPtX5SvNNcTxYadDN2jvnYgR89ANol0y4yawLKf1NfAXSFOU=',
            'date': '12/25',
            'cvc': 'qyDyT+5Fe+n73e2bvfUCVVIujj0IbEclRJk+wt+EZqKpBhCTtgHrnoRoHidQeRTua8xwEUUmTkgTvLremhJjThvP6jG0E05LfZbS2rTe7H/UNKCTtcsOkNI08hWUeCVPyVnbyysqWUFRyes+3UiF8riCnqLzVXwNyORS8tZTgoQ=',
            'identityNumber': '41893607648',
            'name': 'Yusuf',
            'surname': 'IŞIK',
            'companyTitle': '',
            'billingAddress': 'Deneme adres',
            'taxAdministration': '',
            'taxNumber': '',
            'country': 'Türkiye',
            'city': 'Adana',
            'district': 'Aladağ',
            'language': 'tr',
            'email': 'yisik@ydyazilim.com',
            'phoneNumber': '+90 541 453 03 88',
            'licenceCount': 1,
            'paymentContractTexts': {
                'distanceSalesContractText': '',
                'preliminaryInformationFormText': '',
                'withdrawalRightText': ''
            },
            'addressDescription': 'Home',
            'identityNumberType': '0',
            'terms': true,
            'showIdentityNumber': true,
            'productId': 2,
            'expireMonth': 'V7uN16zcvDukjDXHaYapcAo+1rIym5h0u514P3w2WlegstB64rg+twaDZ+q0JFVrAbMi0R3CUT/RAcie2ESVEY1NU8AJR/f/9SoC8FYFOPpwCOBwVYOoJX0gROg0RM4f/aJZ+C8URqoxv9R4kyjHfkE78Q410Y6BMYaO7hI/YtQ=',
            'expireYear': 'LvIa+hvvv4hzwgk75hz6C7XDHXGy9IK5XaavCQSs0ROQ8FA1daSQFSVJPSQ/qT7VSmeQ6BoRF4StkJxDlzgstKsLJnpHakcdloQgSUEdoNCn8fwwMHkQegB6IXHJ0HPptg5weQR2/fXKFEDwO201wWGls9mLD6SUMCUpj/36h1w=',
            'subscriptionType': 1
        };

        PricingServices.paymentRequest(exJson).then(response => {
            setIsLoading(false);
            if (response.status === 401) {
                history.push('/login?redirectUrl=/pricing/buypackage');
            }
            if (response && response.data.status === 'success') {
                if (response.data.threeDSHtml) {
                    setHtmlData(response?.data?.threeDSHtml);
                }
            }
        });
    };


    return (<Box sx={{width: '100%'}} marginTop={{md: 5, xs: 0}}>
        <Modal open={htmlData ? true : false}>
            <Box sx={style}>
                <iframe
                    srcDoc={htmlData}
                    ref={iFrameRef}
                    height={700}
                    width={500}
                    sandbox="allow-scripts allow-forms allow-top-navigation allow-same-origin"
                />
            </Box>
        </Modal>
        <Container padding={{md: 8, xs: 3}}>
            <Grid container>

                {/* Sol form */}
                <Grid item xl={6} md={6} sm={12} order={{md: 1, xs: 2}} marginTop={{md: 0, xs: 5}}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (<Step key={index} {...stepProps}>
                                <StepLabel  {...labelProps}
                                            sx={{'& > span:first-child': {marginLeft: '8px'}}}>{label}</StepLabel>
                            </Step>);
                        })}
                    </Stepper>
                    {activeStep === 0 ? (<InvoiceForm
                        type={'standart'}
                        isLoading={isLoading}
                        onSubmited={onInvoiceFormSubmited}
                    />) : (<Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={values => {
                            onSubmit(values);
                        }}
                    >
                        {({errors, touched, values, handleChange}) => (<Form
                            style={{marginTop: 30}}
                        >
                            <Grid
                                container
                                spacing={3}
                                direction="column"
                                alignItems={{xs: 'stretched', sm: 'stretched'}}
                                justifyContent="center" marginBottom={{md: 5, xs: 4}}>

                                <CreditCards2 width={'100%'} height={250}/>
                                {/* Kart üzerindeki isim */}
                                <Grid item xs={12} md={6}>
                                    <InputLabel sx={{fontWeight: 600}}>{t('cardHolderName')}*</InputLabel>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        autoComplete="false"
                                        name={'cardHolderName'}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        value={values.cardHolderName}
                                        onCopy={e => e.preventDefault()}
                                        onPaste={e => e.preventDefault()}
                                        error={touched.cardHolderName && Boolean(errors.cardHolderName)}
                                        helperText={touched.cardHolderName && errors.cardHolderName}
                                    />
                                </Grid>

                                {/* Kart Numarası*/}
                                <Grid item xs={12} md={6} marginTop={{md: 0, xs: 1}}>
                                    <InputLabel sx={{fontWeight: 600}}>{t('cardNumber')}*</InputLabel>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        name={'cardNumber'}
                                        autoComplete="false"
                                        value={values.cardNumber}
                                        onChange={(e) => {
                                            const re = /[0-9]+/g;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                handleChange(e);
                                            }
                                        }}
                                        onCopy={e => e.preventDefault()}
                                        // onPaste={e => e.preventDefault()}
                                        error={touched.cardNumber && Boolean(errors.cardNumber)}
                                        helperText={touched.cardNumber && errors.cardNumber}
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            const length = value.length;
                                            if (length > 16) {
                                                e.target.value = value.toString().slice(0, 16);
                                            }
                                            const reg = new RegExp('^[0-9]*$');
                                            if ((!reg.test(value))) {
                                                e.target.value = value.toString().slice(0, value.length - 1);
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid container item mt={1}>
                                    <Grid item md={6} xs={12}>
                                        <InputLabel sx={{fontWeight: 600}}>{t('cardExpireDate')}*</InputLabel>
                                        <TextField
                                            fullWidth
                                            name={'date'}
                                            variant="outlined"
                                            value={values.date}
                                            autoComplete="new-password"
                                            onChange={handleChange}
                                            placeholder={t('monthYear')}
                                            onCopy={e => e.preventDefault()}
                                            onPaste={e => e.preventDefault()}
                                            error={touched.date && Boolean(errors.date)}
                                            helperText={touched.date && errors.date}
                                            onInput={(e) => {
                                                const value = e.target.value;
                                                const lastChar = value.charAt(value.length - 1);
                                                const length = value.length;
                                                if (length > 5) {
                                                    e.target.value = value.toString().slice(0, 5);
                                                } else if (e.target.value.length === 2) {
                                                    e.target.value = e.target.value + '/';
                                                } else if (length === 3 && value.charAt(2) === '/') {
                                                    e.target.value = value.replace('/', '');
                                                }
                                                const reg = new RegExp('^[0-9/]*$');

                                                if ((!reg.test(lastChar))) {
                                                    e.target.value = value.toString().slice(0, value.length - 1);
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12} marginTop={{md: 0, xs: 4}}
                                          paddingLeft={{md: '15px', xs: '0'}}>
                                        <InputLabel sx={{fontWeight: 600}}>{t('cvc')}*</InputLabel>
                                        <TextField
                                            fullWidth
                                            name={'cvc'}
                                            type={'text'}
                                            variant="outlined"
                                            value={values.cvc}
                                            onChange={(e) => {
                                                const re = /[0-9]+/g;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    handleChange(e);
                                                }
                                            }}
                                            onCopy={e => e.preventDefault()}
                                            onPaste={e => e.preventDefault()}
                                            error={touched.cvc && Boolean(errors.cvc)}
                                            helperText={touched.cvc && errors.cvc}
                                            InputProps={{
                                                inputProps: {
                                                    max: 999, min: 0, maxLength: 3
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {/* Sözleşme onayları */}
                                <Grid item xs={12} md={6} justifyContent={'space-between'}>
                                    <Box style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                                        <Box width={{md: 150, xs: '45%'}} maxWidth={150}>
                                            <Button
                                                color={'secondary'} size={'large'} variant={'contained'}
                                                onClick={() => handleBack()}
                                                sx={{fontWeight: 500, width: '100%', color: 'black'}}>
                                                {t('back')}
                                            </Button>
                                        </Box>
                                        <Box width={{md: 150, xs: '45%'}} maxWidth={150}>
                                            <LoadingButton
                                                loading={isLoading}
                                                color={'primary'}
                                                type={'submit'}
                                                size={'large'}
                                                variant={'contained'}
                                                sx={{
                                                    fontWeight: 500,
                                                    width: '100%',
                                                    marginLeft: 'auto',
                                                    marginRight: 0
                                                }}>
                                                {t('save')}
                                            </LoadingButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>)}
                    </Formik>)}
                </Grid>

                {/*Sağ taraf*/}
                {selectedPackage && selectedPackage.id &&
                    <Grid item xl={6} md={6} sm={12} xs={12} order={{md: 2, xs: 1}}>
                        <Box
                            marginLeft={{md: i18n.language === 'ar' ? 0 : 10, xs: 0}}
                            marginRight={{md: i18n.language === 'ar' ? 10 : 0, xs: 0}}
                            marginTop={{md: 10, xs: 4}}
                        >
                            <Card sx={{borderRadius: 3}} margin={{md: 5, xs: 0}}>
                                <CardContent>
                                    <Box sx={{textAlign: 'center', padding: '12px'}}>
                                        <img src={image} height={46}/>
                                    </Box>
                                    <Box sx={{marginBottom: 5, textAlign: 'center'}}>
                                        <Button
                                            onClick={() => licenseTypeChange('monthly')}
                                            variant="contained"
                                            color={licenseType === 'monthly' ? 'primary' : 'secondary'}
                                            sx={{
                                                width: 85,
                                                height: 24,
                                                fontSize: '14px',
                                                marginRight: '10px',
                                                color: licenseType === 'monthly' ? '#fff' : '#f44336'
                                            }}
                                        >
                                            {t('packetsMonthly')}
                                        </Button>
                                        <Button
                                            onClick={() => licenseTypeChange('annual')}
                                            variant="contained"
                                            color={licenseType === 'annual' ? 'primary' : 'secondary'}
                                            sx={{
                                                width: 85,
                                                height: 24,
                                                fontSize: '14px',
                                                color: licenseType === 'annual' ? '#fff' : '#f44336'
                                            }}
                                        >
                                            {t('packetsYearly')}
                                        </Button>
                                    </Box>
                                    <Box
                                        width={'100%'}
                                        height={'1px'}
                                        style={{backgroundColor: '#E3E1EA', marginTop: '41px', marginBottom: '15px'}}/>
                                    <Box>
                                        <Typography
                                            sx={{fontWeight: 700, color: '#371C66', fontSize: 17, marginLeft: 0}}>
                                            {t('orderSummary')}
                                        </Typography>
                                    </Box>
                                    <Grid container direction={'row'} sx={{marginTop: 1}} xl={12}>
                                        <Grid item md={9} xs={9}>
                                            {<RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                            >
                                                {packages && packages.map((packageItem, i) => ((packageItem.id === 'standart' || packageItem.id === 'business') &&
                                                    <FormControlLabel
                                                        value={packageItem.id}
                                                        checked={packageItem.id === selectedPackage.id}
                                                        key={i}
                                                        control={<Radio style={{padding: '6px'}}
                                                                        onChange={() => onPackageChange(packageItem)}/>}
                                                        label={<Typography style={{
                                                            fontSize: '14px', fontWeight: 400
                                                        }}>{(i18n.language === 'ar' ? t(packageItem.title.toLowerCase()) : packageItem.title) + ' / ' + (selectedPackage.licenseType === 'monthly' ? t('packetsMonthly') : t('packetsYearly')) + ' ' + t('payment')}</Typography>}/>))}
                                            </RadioGroup>}
                                        </Grid>
                                        <Grid item md={3} xs={3}>
                                            {packages && packages.map((packageItem, i) => {
                                                return (<div key={i}>
                                                    {packageItem.id !== 'enterprise' &&
                                                        <div key={i} style={{
                                                            display: 'flex',
                                                            justifyContent: 'right',
                                                            height: '45px'
                                                        }}>
                                                            {packageItem.id === 'standart' && <Box style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-end',
                                                                alignItems: 'center',
                                                                textAlign: 'right',
                                                                marginBottom: '10px'
                                                            }} width={{md: '100px', xs: '70px'}}>
                                                                {<TextField
                                                                    type="number" size={'small'}
                                                                    sx={{
                                                                        width: '50px',
                                                                        height: '22px',
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-end'
                                                                    }}
                                                                    value={parseInt(packageItem.userCount)}
                                                                    inputProps={{
                                                                        max: 10, min: 1, style: {
                                                                            textAlign: 'center',
                                                                            color: '#371C66',
                                                                            fontWeight: '700',
                                                                            fontSize: '13px',
                                                                            padding: '5.5px 0px 5.5px 10px'
                                                                        }
                                                                    }}
                                                                    onChange={(e) => onStandartUserCountChange(packageItem, e.target.value)}
                                                                    onInput={(e) => {
                                                                        if (parseInt(e.target.value) > 10) {
                                                                            e.target.value = 10;
                                                                        }
                                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                                                    }}/>}
                                                            </Box>}
                                                            {packageItem.id === 'business' && <Box style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-end',
                                                                alignItems: 'center',
                                                                textAlign: 'right',
                                                                marginBottom: '10px'
                                                            }} width={{md: '100px', xs: '70px'}}>
                                                                {<TextField
                                                                    type="number" size={'small'}
                                                                    sx={{
                                                                        width: '50px',
                                                                        height: '22px',
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-end'
                                                                    }}
                                                                    inputProps={{
                                                                        max: 100, min: 11, style: {
                                                                            textAlign: 'center',
                                                                            color: '#371C66',
                                                                            fontWeight: '700',
                                                                            fontSize: '13px',
                                                                            padding: '5.5px 0px 5.5px 10px'
                                                                        }
                                                                    }}
                                                                    value={parseInt(packageItem.userCount)}
                                                                    onChange={(e) => onBusinessUserCountChange(packageItem, e.target.value)}
                                                                    onInput={(e) => {
                                                                        if (parseInt(e.target.value) > 100) {
                                                                            e.target.value = 100;
                                                                        } else if (parseInt(e.target.value) < 11) {
                                                                            e.target.value = 11;
                                                                        } else {
                                                                            if (e.target.value) {
                                                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                                            } else {
                                                                                e.target.value = 11;
                                                                            }
                                                                        }
                                                                    }}/>}
                                                            </Box>}
                                                        </div>}
                                                </div>);
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Box
                                        width={'100%'}
                                        height={'1px'}
                                        style={{backgroundColor: '#E3E1EA', marginTop: '7px', marginBottom: '15px'}}
                                    />
                                    <Box>
                                        <Typography
                                            sx={{fontWeight: 700, color: '#371C66', fontSize: 17, marginLeft: 0}}>
                                            {(i18n.language === 'ar' ? t(selectedPackage.title.toLowerCase()) : selectedPackage.title)}
                                        </Typography>
                                    </Box>
                                    <Grid container direction={'row'}>
                                        <Grid item md={6} xs={6}>
                                            <Box>
                                                <small style={{
                                                    color: '#371C66',
                                                    fontWeight: 600
                                                }}>{t('singleUserPrice')}</small>
                                            </Box>
                                            <Box>
                                                <small style={{
                                                    color: '#371C66', fontWeight: 600
                                                }}>{selectedPackage.userCount} {t('userCount')}</small>
                                            </Box>
                                            <Box>
                                                <small style={{color: '#371C66', fontWeight: 600}}>{t('tax')}</small>
                                            </Box>
                                        </Grid>
                                        <Grid item md={6} xs={6}
                                              style={{textAlign: i18n.language === 'ar' ? 'left' : 'right'}}>
                                            <Box>
                                                <small style={{color: '#371C66', fontWeight: 600}}>
                                                    <CurrencySymbol/>
                                                    {licenseType === 'monthly' ? (i18n.language === 'ar' ? convertDigitIn(selectedPackage.monthlyAmount) : selectedPackage.monthlyAmount) : (i18n.language === 'ar' ? convertDigitIn(selectedPackage.annualAmount) : selectedPackage.annualAmount)}
                                                </small>
                                            </Box>
                                            <Box>
                                                <small
                                                    style={{color: '#371C66', fontWeight: 600}}><CurrencySymbol/>
                                                    {i18n.language === 'ar' ? convertDigitIn(licenseType === 'monthly' ? (selectedPackage.monthlyAmount * selectedPackage.userCount).toFixed(2) : (selectedPackage.annualAmount * selectedPackage.userCount).toFixed(2)) : (licenseType === 'monthly' ? (selectedPackage.monthlyAmount * selectedPackage.userCount).toFixed(2) : (selectedPackage.annualAmount * selectedPackage.userCount).toFixed(2))}
                                                </small>
                                            </Box>
                                            <Box>
                                                <small style={{
                                                    color: '#371C66', fontWeight: 600
                                                }}>+%{i18n.language === 'ar' ? convertDigitIn('18') : 18}</small>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Grid container direction={'row'}
                                      sx={{marginTop: 2, padding: '17px 19px', backgroundColor: '#F6F2FC'}}>
                                    <Grid item md={6} xs={12} textAlign={{
                                        md: i18n.language === 'ar' ? 'right' : 'left',
                                        xs: i18n.language === 'ar' ? 'left' : 'right'
                                    }}>
                                        <Typography
                                            sx={{
                                                fontWeight: 600, color: '#371C66', fontSize: 17, marginLeft: 0
                                            }}>{t('totalAmount')}</Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Typography
                                            sx={{
                                                textAlign: i18n.language === 'ar' ? 'left' : 'right',
                                                color: '#371C66',
                                                marginLeft: 0
                                            }}>
                                            <Typography component={'strong'} style={{fontWeight: 700, fontSize: 17}}>
                                                <CurrencySymbol/>
                                                {i18n.language === 'ar' ? convertDigitIn(licenseType === 'monthly' ? (selectedPackage.monthlyAmount * selectedPackage.userCount + ((selectedPackage.monthlyAmount * selectedPackage.userCount) * 0.18)).toFixed(2) : (selectedPackage.annualAmount * selectedPackage.userCount + ((selectedPackage.annualAmount * selectedPackage.userCount) * 0.18)).toFixed(2)) : licenseType === 'monthly' ? (selectedPackage.monthlyAmount * selectedPackage.userCount + ((selectedPackage.monthlyAmount * selectedPackage.userCount) * 0.18)).toFixed(2) : (selectedPackage.annualAmount * selectedPackage.userCount + ((selectedPackage.annualAmount * selectedPackage.userCount) * 0.18)).toFixed(2)}
                                            </Typography>
                                            <Typography
                                                component={'span'}
                                                style={{
                                                    fontSize: '12px',
                                                    marginRight: i18n.language === 'ar' ? '10px' : '0'
                                                }}
                                            >
                                                {licenseType === 'monthly' ? t('licenseMonth') : t('licenseYear')}
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Box>
                    </Grid>}

                <Button
                    color={'secondary'} size={'large'} variant={'contained'} onClick={() => onSubmit()}
                    sx={{fontWeight: 500, width: '100%', color: 'black'}}>
                    Göndeeeerr
                </Button>
            </Grid>
        </Container>
    </Box>);
};

export default BuyPackage;
