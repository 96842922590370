import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {Button, Menu} from '@mui/material';
import {useSelector} from 'react-redux';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const TimeTextField = ({value, name, InputLabelProps, inputProps, onChange, ...rest}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [hour, setHour] = useState(value ? value.split(':')[0] : '');
    const [minute, setMinute] = useState(value ? value.split(':')[1] : '');
    const [ampm, setAmPm] = useState(null);
    const open = Boolean(anchorEl);
    const hours = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '00'];
    const minutes = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
    const countryCode = useSelector((state) => state.main.countryCode);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        onChange(hour + ':' + minute + ' ' + (ampm ? ampm : ''));
    };

    const hourChange = (val) => {
        setHour(val);
        onChange(hour + ':' + minute + ' ' + (ampm ? ampm : ''));
    };

    const minuteChange = (val) => {
        setMinute(val);
        onChange(hour + ':' + minute + ' ' + (ampm ? ampm : ''));
    };

    const amPmChange = (val) => {
        setAmPm(val);
        onChange(hour + ':' + minute + ' ' + (ampm ? ampm : ''));
    };

    useEffect(() => {
        if (value) {
            setHour(value ? value.split(':')[0] : '');
            setMinute(value ? value.split(':')[1] : '');
        }
    }, [value]);

    return (
        <Box
            style={{position: 'relative', cursor: 'pointer'}}
            {...rest}
        >
            <TextField
                type="text"
                name={name}
                value={hour + ':' + minute + ' ' + (ampm ? ampm : '')}
                InputLabelProps={InputLabelProps}
                inputprops={{inputProps, ...{readOnly: true}}}
                sx={{width: '100%', marginLeft: 1}}
                onClick={handleClick}
            />
            <AccessTimeIcon
                style={{position: 'absolute', right: 5, top: 16, color: 'rgba(0, 0, 0, 0.54)', pointerEvents: 'none'}}/>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <li style={{width: countryCode !== 'TR' ? '180px' : '100px'}}>
                    <Box style={{width: '100%', display: 'flex', padding: '4px'}}>
                        <Box className={'scroll-hide'} style={{
                            width: countryCode !== 'TR' ? '33%' : '50%',
                            minWidth: 'auto',
                            paddingRight: 2,
                            maxHeight: '300px'
                        }}>
                            {
                                // eslint-disable-next-line no-unused-vars
                                hours.map((item, index) => (<Button key={index} onClick={(e) => {
                                    hourChange(item);
                                }} style={{
                                    width: '100%',
                                    minWidth: 'auto',
                                    display: (countryCode !== 'TR' && index > 11 ? 'none' : 'block')
                                }} variant={item === hour ? 'contained' : 'text'}>{item}</Button>))
                            }
                        </Box>
                        <Box className={'scroll-hide'} style={{
                            width: countryCode !== 'TR' ? '33%' : '50%',
                            minWidth: 'auto',
                            display: 'inline-block',
                            maxHeight: '300px',
                        }}>
                            {
                                // eslint-disable-next-line no-unused-vars
                                minutes.map((item, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => minuteChange(item)}
                                        style={{width: '100%', minWidth: 'auto', display: 'block'}}
                                        variant={item === minute ? 'contained' : 'text'}>{item}
                                    </Button>))
                            }
                        </Box>
                        {

                            countryCode !== 'TR' && <Box style={{width: '33%', minWidth: 'auto', paddingLeft: 2}}>
                                <Button
                                    onClick={() => amPmChange('am')}
                                    style={{width: '100%', minWidth: 'auto', display: 'block', maxHeight: '300px'}}
                                    variant={ampm === 'am' ? 'contained' : 'text'}>AM</Button>
                                <Button
                                    onClick={() => amPmChange('pm')}
                                    style={{width: '100%', minWidth: 'auto', display: 'block'}}
                                    variant={ampm === 'pm' ? 'contained' : 'text'}>PM</Button>
                            </Box>
                        }
                    </Box>
                </li>
            </Menu>
        </Box>
    );
};

TimeTextField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    InputLabelProps: PropTypes.object,
    inputProps: PropTypes.object,
    onChange: PropTypes.func,
};

export default TimeTextField;
