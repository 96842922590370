import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, {useState} from 'react';
import Swal from 'sweetalert2';
import InvoiceForm from '../components/InvoiceForm';
import {PricingServices} from '../../../../services';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const TrialStart = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const onInvoiceFormSubmited = (values) => {
        setIsLoading(true);
        var promise = PricingServices.startTrial(values);

        promise.then(response => {
            setIsLoading(false);
            if (response) {
                let timerInterval;
                Swal.fire({
                    icon: 'success',
                    title: t('success'),
                    timer: 4000,
                    timerProgressBar: true,
                    showCloseButton: false,
                    showConfirmButton: true,
                    showDenyButton: false,
                    confirmButtonText: t('getStarted'),
                    text: t('trialStartGetStartedMessage'),
                    willClose: () => {
                        clearInterval(timerInterval);
                        history.push('/');
                    }
                });
            }
        });
    };

    return (
        <Box sx={{width: '100%'}} marginTop={{md: 5, xs: 0}}>

            <Grid container padding={{md: 8, xs: 3}}>
                {/* Sol form */}
                <Grid item xl={6} md={6} sm={12}>
                    <InvoiceForm
                        type={'trial'}
                        onSubmited={onInvoiceFormSubmited}
                        isLoading={isLoading}
                    />
                </Grid>

                {/* Sağ panel */}
                <Grid item xl={6} md={6} sm={12}>
                    <Box marginLeft={{md: 10, xs: 0}} marginTop={{md: 0, xs: 7}}>
                        <Box>
                            <Typography fontSize={{md: '23px', xs: '20px'}} sx={{
                                fontWeight: 600,
                                color: '#000',
                                marginLeft: 5
                            }}>{t('telepatiFreeTrial')}</Typography>
                        </Box>
                        <ul style={{color: '#000', paddingLeft: '22px'}}>
                            <li>{t('telepatiFreeTrialText1')}</li>
                            <br/>
                            <li>{t('telepatiFreeTrialText2')}</li>
                            <br/>
                            <li>{t('telepatiFreeTrialText3')}</li>
                            <br/>
                            <li>{t('telepatiFreeTrialText4')}</li>
                            <br/>
                            <li>{t('telepatiFreeTrialText5')}</li>
                            <br/>
                        </ul>
                        <Box>
                            <Typography sx={{
                                fontWeight: 600,
                                color: '#000',
                                fontSize: 23,
                                marginLeft: 5
                            }}>{t('productFeatures')}</Typography>
                        </Box>
                        <ul style={{color: '#000', paddingLeft: '22px'}}>
                            <li>{t('productFeaturesText1')}</li>
                            <br/>
                            <li>{t('productFeaturesText2')}</li>
                            <br/>
                            <li>{t('productFeaturesText3')}</li>
                            <br/>
                            <li>{t('productFeaturesText4')}</li>
                            <br/>
                            <li>{t('productFeaturesText5')}</li>
                        </ul>
                    </Box>
                </Grid>
            </Grid>

        </Box>
    );
};

export default TrialStart;
